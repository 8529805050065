import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'

export const PageBanner = ({
  pageName,
  pageTitle,
}: {
  pageName: string
  pageTitle: string
}): ReactElement => {
  return (
    <Box
      width="100%"
      backgroundImage="linear-gradient(to right, #FFC719 0%, #eb662d 40%, #FF2200 90%)"
      textAlign="center"
      padding={{ base: '3', md: '7' }}
    >
      <Flex justifyContent="center">
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.200" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink href="/" color="gray.200">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink href="#" color="gray.200">
              {pageName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Heading as="h1" fontSize="3xl" color="gray.200">
        {pageTitle}
      </Heading>
    </Box>
  )
}
