import { Box, Image, Flex } from '@chakra-ui/react'
import { ReactElement } from 'react'
import CourseCard from './CourseCard'
import { programmes } from '../../constants/programmes'
import RedStar from '../../assets/red-star.svg'
import { APPLICATION_MAX_WIDTH } from '../../constants'
import { SectionTitle } from '../common'

export function ProgrammesSection(): ReactElement {
  return (
    <Box
      padding="8"
      data-testid="home-programmes-section"
      width="100%"
      maxWidth={APPLICATION_MAX_WIDTH}
      margin="auto"
      as="section"
    >
      <Box position="absolute" right={{ base: '0', lg: '10' }}>
        <Image src={RedStar} width={{ base: '50%' }} alt="red star" />
      </Box>
      <SectionTitle
        titlePart1="Begin your learning"
        titlePart2="journey with us today! 🚀"
        description="Our comprehensive programme could be your gateway to mastering the skills and technologies that power the internet."
      />
      <Flex
        direction={{ base: 'column', md: 'row' }}
        flexWrap={{ md: 'wrap', lg: 'nowrap' }}
        gap="4"
        py={8}
        width="100%"
        overflowX="scroll"
      >
        {programmes.map(programme => (
          <CourseCard
            key={programme.title}
            title={programme.title}
            description={programme.description}
            image={programme.image}
            learningOutcomes={programme.learningOutcomes}
            link={programme.link}
            duration={programme.duration}
          />
        ))}
      </Flex>
    </Box>
  )
}
