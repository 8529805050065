import React from 'react'
import {
  Card,
  CardBody,
  Stack,
  Heading,
  Text,
  Image,
  Button,
} from '@chakra-ui/react'
import { Panelist } from '../../../constants'

interface ProfileCardProps {
  profile: Panelist
  showFullAbout: boolean
  toggleAbout: () => void
}

export const ProfileCard: React.FC<ProfileCardProps> = ({
  profile,
  showFullAbout,
  toggleAbout,
}): React.ReactElement => {
  return (
    <Card
      boxShadow="md"
      width="320px"
      minWidth="320px"
      data-testid="profile-card"
    >
      <CardBody>
        <Image src={profile.image} alt={profile.name} width="100%" />
        <Stack spacing="2" p="4">
          <Heading fontSize="xl">{profile.name}</Heading>
          <Text fontSize="lg">{profile.title}</Text>
          <Text>({profile.company})</Text>
          <Text my="3">
            {showFullAbout
              ? profile.about
              : profile.about.slice(0, 200) + '...'}
          </Text>
          <Button variant="link" colorScheme="blue" onClick={toggleAbout}>
            {showFullAbout ? 'Show Less' : 'Read More'}
          </Button>
        </Stack>
      </CardBody>
    </Card>
  )
}
