import { Box, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'

import {
  APPLICATION_MAX_WIDTH,
  DEVELOPER_APPLICATION_FORM_URL,
} from '../../constants'
import { Link } from '../common'
import heroImage from '../../assets/hero-image.svg'
import redSpiralArrow from '../../assets/red-spiral-arrow.svg'
import curvyRedArrow from '../../assets/curvy-red-arrow.svg'

export const Hero: React.FC = () => {
  return (
    <Box maxWidth={APPLICATION_MAX_WIDTH} margin="auto" as="section">
      <Image
        src={curvyRedArrow}
        alt="Curvy Red Arrow"
        height="auto"
        width={{ base: '100px', lg: 'unset' }}
        position="absolute"
        top="60px"
        left="10px"
      />
      <Flex
        direction={{ base: 'column-reverse', lg: 'row' }}
        data-testid="home-hero"
        padding="8"
        gap="8"
      >
        <VStack
          width={{ base: '100%', lg: '45%' }}
          alignItems={{ base: 'center', lg: 'flex-start' }}
          textAlign={{ base: 'center', lg: 'left' }}
          justifyContent="center"
        >
          <Heading as="h1" fontSize={{ base: '3xl', md: '5xl' }} marginY="5">
            <Box
              as="span"
              borderBottom="5px solid"
              borderColor="accent.500"
              fontSize="inherit"
            >
              Excel
            </Box>{' '}
            in tech through our high quality{' '}
            <Box
              as="span"
              borderBottom="5px solid"
              borderColor="orange.900"
              fontSize="inherit"
            >
              mentorship
            </Box>{' '}
            and learning support. 🚀
          </Heading>
          <Text fontSize={{ base: 'xl', md: '2xl' }} my="5" color="gray.800">
            Are you interested in reaching world-class proficiency in data
            analysis, web development, frontend, backend engineering, or even
            fullstack engineering?
          </Text>
          <Link variant="buttonSecondary" to={DEVELOPER_APPLICATION_FORM_URL}>
            Register Now
          </Link>
          <Image
            src={redSpiralArrow}
            alt="Red Spiral Arrow"
            height="auto"
            width={{ base: '50px', lg: 'unset' }}
            position="relative"
            left={{ base: '120px', lg: '180px' }}
            bottom="30px"
          />
        </VStack>

        <Flex width={{ base: '100%', lg: '55%' }}>
          <Image src={heroImage} alt="Hero Image" height="auto" width="96%" />
        </Flex>
      </Flex>
    </Box>
  )
}
