import { Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

import {
  DEVELOPER_APPLICATION_FORM_URL,
  siteNavLinks,
} from '../../../constants'
import { Link } from '../Link'

export const NavbarLinks: React.FC = () => {
  const { pathname, hash } = useLocation()
  return (
    <>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        marginTop={{ base: '5', md: '0' }}
      >
        {siteNavLinks.map(link => (
          <Link
            key={link.name}
            to={link.url}
            isExternal={link.isExternal}
            variant={
              link.url === `${pathname}${hash}` ? 'secondary' : 'primary'
            }
            fontSize="md"
            mr={{ base: '0', md: '10' }}
            marginBottom={{ base: '5', md: '0' }}
          >
            {link.name}
          </Link>
        ))}
        <Link
          variant="buttonSecondary"
          to={DEVELOPER_APPLICATION_FORM_URL}
          fontSize="md"
          minWidth="165px"
          marginBottom={{ base: '5', md: '0' }}
        >
          Register Now
        </Link>
      </Flex>
    </>
  )
}
