import { ComponentStyleConfig } from '@chakra-ui/react'
import { shapeToObject } from '../../utils'
import { Button } from './buttons'

const ButtonVariants = shapeToObject(Button.variants)

export const Link: ComponentStyleConfig = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    button: { ...ButtonVariants.primary, textDecoration: 'none' },
    buttonMuted: { ...ButtonVariants.muted, textDecoration: 'none' },
    buttonSecondary: { ...ButtonVariants.secondary, textDecoration: 'none' },
    buttonInvertedSecondary: {
      ...ButtonVariants.invertedSecondary,
      textDecoration: 'none',
    },
    buttonInvertedPrimary: {
      ...ButtonVariants.invertedPrimary,
      textDecoration: 'none',
    },
    buttonOutlineSecondary: {
      ...ButtonVariants.outlineSecondary,
      textDecoration: 'none',
    },
    primary: {
      _focus: { textDecoration: 'underline', color: 'primary.900' },
      _hover: { textDecoration: 'underline', color: 'primary.900' },
      color: 'primary.500',
      fontWeight: 'semibold',
      fontSize: 'lg',
    },
    secondary: {
      _focus: { textDecoration: 'underline' },
      _hover: { textDecoration: 'underline' },
      color: 'orange.900',
      fontWeight: 'semibold',
      fontSize: 'lg',
    },
    strong: {
      _focus: { textDecoration: 'underline' },
      _hover: { textDecoration: 'underline' },
      color: 'primary.700',
      fontSize: 'inherit',
      fontWeight: '600',
      textDecoration: 'underline',
    },
    muted: {
      _focus: { textDecoration: 'underline' },
      _hover: { textDecoration: 'underline' },
      color: 'gray.300',
      fontSize: 'inherit',
    },
    menuitem: {
      _focus: { textDecoration: 'underline' },
      _hover: { textDecoration: 'underline' },
      color: 'gray.700',
      fontSize: 'lg',
    },
  },
}
