import React from 'react'
import {
  Heading,
  Box,
  Text,
  Flex,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'

import blueTag from '../../assets/blue-tag.png'
import redTag from '../../assets/red-tag.png'
import { APPLICATION_MAX_WIDTH } from '../../constants'
import { DeliveryModeCard } from './DeliveryCard'

export const Delivery: React.FC = () => {
  return (
    <Box
      maxWidth={APPLICATION_MAX_WIDTH}
      width={{ base: '90%' }}
      mx={'auto'}
      my={'50px'}
      data-testid="delivery-mode"
    >
      <Heading as="h2" mb="5">
        Delivery Modes
      </Heading>
      <Box my={8}>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          gap={8}
        >
          <DeliveryModeCard tag={blueTag} title="Self-Paced Learning">
            <>
              <Text py={4}>
                Self-Paced Learning is a self-paced delivery mode that provides
                you with a structured learning path, resources, and the support
                system to help you stay on track and to succeed. You can expect:
              </Text>
              <UnorderedList color="gray.700">
                <ListItem>Weekly sessions with professionals</ListItem>
                <ListItem>Tailored and top-notch learning resources</ListItem>
                <ListItem>Comprehensive learning support</ListItem>
                <ListItem>Job updates and support</ListItem>
              </UnorderedList>
            </>
          </DeliveryModeCard>
          <DeliveryModeCard tag={redTag} title="Mentorship">
            <>
              <Text py={4}>
                In addition to all the benefits offered in Self-Paced Learning,
                you will also have a mentor (a professional with a minimum of 2
                years of work experience). You can expect:
              </Text>
              <UnorderedList color="gray.700">
                <ListItem>Learning guidance and mentorship</ListItem>
                <ListItem>Projects assessment and feedback</ListItem>
                <ListItem>Career development support</ListItem>
                <ListItem>
                  Certificate of completion (at the end of the program)
                </ListItem>
              </UnorderedList>
            </>
          </DeliveryModeCard>
        </Flex>
      </Box>
    </Box>
  )
}
