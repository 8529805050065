/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link as ChaLink, LinkProps as ChaLinkProps } from '@chakra-ui/react'
import {
  Link as ReactRouterDomLink,
  // eslint-disable-next-line import/named
  LinkProps as RRDLinkProps,
} from 'react-router-dom'

export type LinkProps = ChaLinkProps & RRDLinkProps

export const Link: React.FC<LinkProps> = ({
  children,
  to,
  isExternal,
  ...props
}) => {
  if (isExternal) {
    return (
      <ChaLink
        href={to as string}
        rel="noopener noreferrer"
        isExternal
        fontSize="xl"
        {...props}
      >
        {children}
      </ChaLink>
    )
  }
  return (
    <ChaLink as={ReactRouterDomLink} to={to} fontSize="xl" {...props}>
      {children}
    </ChaLink>
  )
}
