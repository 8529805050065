import { Card, CardBody, Stack, Heading, Text, Image } from '@chakra-ui/react'
import React from 'react'
import { LiveClass } from '../../../constants'

export const LiveClassCard = ({
  liveClass,
}: {
  liveClass: LiveClass
}): React.ReactElement => {
  return (
    <Card
      boxShadow="md"
      width="350px"
      minWidth="350px"
      data-testid="liveClass-card"
    >
      <CardBody>
        <Image
          src={liveClass.flyer}
          alt={liveClass.title}
          width="350px"
          objectFit="cover"
          borderTopRadius="5"
        />
        <Stack spacing="3" p="4">
          <Heading
            fontSize={'xl'}
          >{`${liveClass.title} (${liveClass.edition})`}</Heading>
          <Text>{liveClass.description}</Text>
        </Stack>
      </CardBody>
    </Card>
  )
}
