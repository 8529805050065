import { ReactElement } from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import { APPLICATION_MAX_WIDTH } from '../../constants'
import { CostContent } from '../common'

export function CostSection(): ReactElement {
  return (
    <Box
      as="section"
      maxWidth={APPLICATION_MAX_WIDTH}
      marginX="auto"
      paddingX="7"
      paddingY="10"
      textAlign="center"
      marginY="10"
      data-testid="cost-section"
      width={'100%'}
      bgColor="#3649681A"
    >
      <Heading as="h2">At What Cost?</Heading>
      <Text color="gray.700">
        You don&apos;t have to burn through your life savings. Our fees are very
        affordable and super flexible.
      </Text>
      <CostContent />
    </Box>
  )
}
