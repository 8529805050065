export function getLearningPath(learningInterest: string): string {
  switch (learningInterest.toLocaleLowerCase()) {
    case 'web development':
    case 'frontend engineering':
    case 'backend engineering':
    case 'fullstack engineering':
      return 'engineering'
    case 'data analysis':
    case 'data science':
    case 'data engineering':
      return 'Data Analysis'
    case 'product design':
    case 'product management':
      return 'product'
    default:
      return 'unknown'
  }
}

export function getCourseRegistrationLink(course: string): string {
  return `/register?course=${course}`
}
