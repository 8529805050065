import { attempt, flowRight, isError } from 'lodash'

const asBool = (x: string | null): boolean => !!x && !['0', 'false'].includes(x)

const newAttempt = flowRight(x => {
  if (isError(x)) {
    return null
  }
  return x
}, attempt)
/**
 * Returns a convenience function for checking if the given storage has a key.
 */
export function hasStorageKey(storage: Storage) {
  return (key: string): boolean => Object.hasOwnProperty.call(storage, key)
}

/**
 * Convenience function for reading boolean values from window storage.
 * Will convert `0`, `''`, `false` to false, everything else to true.
 */
export function getStorageBoolean(storage: Storage) {
  return (key: string): boolean | null => {
    return hasStorageKey(storage)(key) ? asBool(storage.getItem(key)) : null
  }
}

/**
 * Convenience function for reading JSON from window storage.
 * If, while reading the value, parsing fails, `null` is returned.
 */
export function getStorageJson(storage: Storage) {
  return (key: string): unknown | null => {
    return newAttempt(JSON.parse, [storage.getItem(key) || 'null'])
  }
}

/**
 * Returns a convenience function for setting some JSON using window storage.
 * Stringifies the given `value` set sets it on the given `storage`.
 */
export function setStorageJson(storage: Storage) {
  return <T>(key: string, value: NonNullable<T>): void => {
    return storage.setItem(key, JSON.stringify(value))
  }
}

export const hasLocalStorageItem = hasStorageKey(localStorage)
export const hasSessionStorageItem = hasStorageKey(sessionStorage)

export const getLocalStorageBoolean = getStorageBoolean(localStorage)
export const getLocalStorageJson = getStorageJson(localStorage)
export const setLocalStorageJson = setStorageJson(localStorage)

export const getSessionStorageBoolean = getStorageBoolean(sessionStorage)
export const getSessionStorageJson = getStorageJson(sessionStorage)
export const setSessionStorageJson = setStorageJson(sessionStorage)

export const getLocalStorage = localStorage.getItem.bind(localStorage)
export const setLocalStorage = localStorage.setItem.bind(localStorage)
export const delLocalStorage = localStorage.removeItem.bind(localStorage)

export const getSessionStorage = sessionStorage.getItem.bind(sessionStorage)
export const setSessionStorage = sessionStorage.setItem.bind(sessionStorage)
export const delSessionStorage = sessionStorage.removeItem.bind(sessionStorage)
