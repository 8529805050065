import { shortCourses } from '../constants/shortCourses'
import { programmes } from '../constants/programmes'
import { ProgrammeType } from '../types'

const allCourses = [...programmes, ...shortCourses]

export const isPartialMatch = (courseTitle: string, title: string): boolean => {
  const normalizedCourseTitle = courseTitle.toLowerCase()
  const normalizedTitle = title.toLowerCase()

  return (
    normalizedTitle === normalizedCourseTitle ||
    normalizedCourseTitle.includes(normalizedTitle)
  )
}

export const getCourse = (courseTitle: string): ProgrammeType | undefined => {
  const course = allCourses.find(item => isPartialMatch(courseTitle, item.key))
  return course
}
