import { Flex, Heading, Link, Image, HStack } from '@chakra-ui/react'
import React from 'react'
import { Link as ReactRouterDomLink, useLocation } from 'react-router-dom'
import {
  APPLICATION_MAX_WIDTH,
  DEVELOPER_APPLICATION_FORM_URL,
} from '../../constants'
import CTAPattern from '../../assets/CTA-print.svg'
import SpiralArrow from '../../assets/spiral-arrow.svg'

export const CallToAction = (): React.ReactElement => {
  const location = useLocation()
  const { pathname } = location

  return (
    <Flex
      py="10"
      width="100%"
      background={`linear-gradient(to right, #FFC719 0%, #eb662d 51%, #FF2200 100%), url(${CTAPattern})`}
      backgroundBlendMode="overlay"
      direction={{ base: 'column', md: 'row' }}
      data-testid="home-call-to-action"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px="10"
        mx="auto"
        direction={{ base: 'column', md: 'row' }}
        maxWidth={APPLICATION_MAX_WIDTH}
        width={{ base: '100%', lg: '80%' }}
        gap="6"
      >
        <Heading as="h2" color="gray.100" py="4">

          {pathname == '/webinarr'
            ? 'Save your spot!'
            : 'Ready to become a leader in tech?'}

        </Heading>
        <HStack>
          <Image
            src={SpiralArrow}
            position="relative"
            top={{ base: '-20px' }}
            right={{ base: '3px', md: '0', lg: '0', xl: '0' }}
            width="20%"
            alt="Spiral Arrow"
          />

          <Link
            variant="buttonInvertedSecondary"
            as={ReactRouterDomLink}
            isExternal
            to={
              pathname == '/webinarr'
                ? '/webinar'
                : DEVELOPER_APPLICATION_FORM_URL
            }
            fontSize={{ base: 'sm', lg: 'lg' }}
            width={{ base: '60%', md: '100%' }}
          >
            Register Now
          </Link>
        </HStack>
      </Flex>
    </Flex>
  )
}
