import React from 'react'

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from '@chakra-ui/react'
import { NavbarLinks } from './NavbarLinks'
import { HamburgerIcon } from '@chakra-ui/icons'
import { useLocation } from 'react-router-dom'

export const MobileNavbarDrawer: React.FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { pathname, hash } = useLocation()

  React.useEffect(() => {
    // close the drawer when the route changes
    onClose()
  }, [pathname, hash, onClose])

  return (
    <>
      <Button
        display={{ base: 'block', md: 'none' }}
        variant="invertedSecondary"
        aria-label="Show Mobile Nav menu"
        backgroundColor="gray.200"
        onClick={onOpen}
        padding="2"
      >
        <HamburgerIcon fontSize="3xl" />
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay backgroundColor="overlay.300" />
        <DrawerContent backgroundColor="white" padding="4" width="450px">
          <Flex
            justifyContent="flex-end"
            py="4"
            px="7"
            data-testid="nav-drawer-content"
          >
            <DrawerCloseButton
              data-testid="nav-drawer-close-button"
              color="gray.700"
            />
          </Flex>
          <DrawerBody>
            <NavbarLinks />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
