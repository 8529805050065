import { theme as defaultTheme } from '@chakra-ui/react'
import '@fontsource/space-grotesk'
import '@fontsource/roboto'

const headingFontFamily =
  "'Space Grotesk', Montserrat, Roboto,  Arial, sans-serif"
const bodyFontFamily = 'Roboto, Montserrat, Arial, sans-serif'

export const fonts = {
  ...defaultTheme.fonts,
  body: bodyFontFamily,
  heading: headingFontFamily,
}

export const fontSizes = {
  xxs: '0.5rem', // 8px
  xs: '0.625rem', // 10px
  sm: '0.813rem', // 13px
  md: '1rem', // 16px
  lg: '1.25rem', // 20px
  xl: '1.375rem', // 22 px
  '2xl': '1.625rem', // 26px
  '3xl': '2.313rem', // 37px
  '4xl': '3.25rem', // 52px
  '5xl': '4.125rem', // 66px
  '6xl': '6.563rem', // 105px
}
