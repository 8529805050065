import React from 'react'
import { Box, Text, Heading, Divider } from '@chakra-ui/react'
import webinarHeroImage from '../../assets/webinarHero.png'
import { APPLICATION_MAX_WIDTH, LiveClass } from '../../constants'

export const LiveClassHero = ({
  mainClass,
  isPastDate,
}: {
  mainClass: LiveClass
  isPastDate: boolean
}): React.ReactElement => {
  return (
    <Box
      bgImage={webinarHeroImage}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      width="100%"
      data-testid="live-classes-hero"
    >
      <Box
        maxWidth={APPLICATION_MAX_WIDTH}
        width="100%"
        marginX="auto"
        padding={{ base: '5', md: '10' }}
      >
        {!isPastDate && (
          <>
            <Heading
              as="h4"
              fontWeight="extrabold"
              fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
            >
              Live Class
            </Heading>
            <Box
              display="flex"
              flexDirection={{ base: 'column', md: 'row' }}
              gap={3}
              my={4}
            >
              <Text
                bgColor="yellow.300"
                fontWeight="bold"
                fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
                width={'fit-content'}
                paddingX={3}
              >
                {mainClass.edition}
              </Text>
              <Box display="flex" flexDirection="row" gap={3}>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
                  width={'fit-content'}
                >
                  From {mainClass.startDate}
                </Text>
              </Box>
            </Box>
          </>
        )}

        <Heading
          as="h1"
          fontWeight="extrabold"
          fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
          width={{ base: '100%', md: '70%' }}
          marginY={{ base: '7', md: '12' }}
        >
          {!isPastDate ? mainClass.title : 'No Upcoming Class'}
        </Heading>

        {!isPastDate && (
          <>
            <Box
              display="flex"
              flexDirection="row"
              marginBottom={{ base: '3%', md: '5%' }}
              gap={3}
            >
              <Text
                fontWeight="bold"
                fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
                width={'fit-content'}
              >
                {mainClass.classDays[0]}
              </Text>
              <Divider
                border={'1px solid red'}
                borderTopWidth={{ base: '23px', md: '29px', lg: '37px' }}
                orientation="vertical"
              />
              <Text
                fontWeight="bold"
                fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
                width={'fit-content'}
              >
                {mainClass.classDays[1]}
              </Text>
              <Divider
                border={'1px solid red'}
                borderTopWidth={{ base: '23px', md: '29px', lg: '37px' }}
                orientation="vertical"
              />
              <Text
                fontWeight="bold"
                fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
                width={'fit-content'}
              >
                {mainClass.classDays[2]}
              </Text>
            </Box>
            <Text
              fontWeight="semibold"
              fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
            >
              Duration: {mainClass.duration}
            </Text>
          </>
        )}
      </Box>
    </Box>
  )
}
