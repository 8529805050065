const colors = {
  white: '#FFF',
  black: '#000',
  transparent: 'transparent',
  modalOverlay: 'rgba(0, 40, 80, 0.4)',
  overlay: {
    100: 'rgba(0, 0, 0, 0.1)',
    200: 'rgba(0, 0, 0, 0.2)',
    300: 'rgba(0, 0, 0, 0.3)',
    400: 'rgba(0, 0, 0, 0.4)',
    500: 'rgba(0, 0, 0, 0.5)',
    600: 'rgba(0, 0, 0, 0.6)',
    700: 'rgba(0, 0, 0, 0.7)',
    800: 'rgba(0, 0, 0, 0.8)',
    900: 'rgba(0, 0, 0, 0.9)',
  },
  primary: {
    500: '#364968',
    900: '#2D3142',
  },
  gray: {
    100: '#FFFFFF',
    200: '#FAFAFA',
    300: '#DDD',
    400: '#CCC',
    500: '#999',
    600: '#666',
    700: '#333',
    800: '#222222',
    900: '#000000',
  },
  green: {
    200: '#D8FBEE',
    500: '#03A64A',
    800: '#034923',
  },
  orange: {
    100: '#fdeee8',
    300: '#f4aa8a',
    500: '#EF8354',
    700: '#eb662d',
    900: '#d24d14',
  },
  blue: {
    200: '#E8F5FD',
    300: '#D1EBF9',
    500: '#066CA7',
    800: '#014063',
    900: '#364968',
  },
  purple: {
    200: '#EEE9FC',
    500: '#442E83',
    800: '#2D1D5A',
  },
  red: {
    200: '#FFF5F5',
    300: '#FF2200',
    500: '#DC1E1E',
    800: '#630F0F',
  },
  accent: {
    500: '#FFF384',
  },
}

export default colors
