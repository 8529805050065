import React from 'react'
import { Heading, Box } from '@chakra-ui/react'
import { CostContent } from '../common'
import { APPLICATION_MAX_WIDTH } from '../../constants'

export const PricingSection: React.FC = () => {
  return (
    <Box
      maxWidth={APPLICATION_MAX_WIDTH}
      width={{ base: '90%' }}
      mx={'auto'}
      my={'50px'}
      data-testid="pricing-section"
    >
      <Heading as="h2" mb="5">
        At What Cost?
      </Heading>
      <Box my={4} p={5} textAlign="center" bgColor="#3649681A">
        <CostContent />
      </Box>
    </Box>
  )
}
