import { DCLC_BACKEND_API_KEY, DCLC_BACKEND_API_URL } from '../constants'
import { PaymentRequestBody } from '../types'

export async function getPaymentDetails(
  payload: PaymentRequestBody,
  retryCount = 0,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  try {
    const response = await fetch(`${DCLC_BACKEND_API_URL}/payments`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${DCLC_BACKEND_API_KEY}`,
      },
    })
    if (response.status === 200) {
      const { data } = await response.json()
      return data
    } else if (response.status >= 500 && retryCount < 3) {
      console.log('Retrying payment details request...')
      return getPaymentDetails(payload, retryCount + 1)
    }
  } catch (error) {
    console.error(error)
  }
}
