import theoOkafor from '../assets/mentors/theo-okafor.webp'
import sullivan from '../assets/mentors/sullivan.webp'
import maryChukwuma from '../assets/mentors/mary-brenda.webp'
import ritaMaria from '../assets/mentors/rita-maria.webp'
import stanNdunwere from '../assets/mentors/stan-ndunwere.webp'
import abiolaBakare from '../assets/mentors/abiola-bakare.webp'
import joseNriagu from '../assets/mentors/jose-nriagu.webp'
import goodnessOsumah from '../assets/mentors/goodness-osumah.webp'

export interface Mentor {
  name: string
  image: string
  description: string
  linkedin: string
  github: string
  twitter: string
  website: string
}

export const mentors: Mentor[] = [
  {
    name: 'Theo Okafor',
    image: theoOkafor,
    description: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/theo-okafor/',
    github: 'https://github.com/TheoOkafor',
    twitter: 'https://twitter.com/theookafor_',
    website: '',
  },
  {
    name: 'Sullivan Wisdom',
    image: sullivan,
    description: 'Frontend Developer',
    linkedin: 'https://www.linkedin.com/in/sullivanwisdom/',
    github: 'https://github.com/wiztemple',
    twitter: 'https://twitter.com/_wizsullivan',
    website: '',
  },
  {
    name: 'Stanley C. Ndunwere',
    image: stanNdunwere,
    description: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/stanleyndunwere/',
    github: 'https://github.com/StanleyNdunwere',
    twitter: '',
    website: '',
  },
  {
    name: 'Mary Chukwuma',
    image: maryChukwuma,
    description: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/marychuks',
    github: 'https://github.com/brendagold',
    twitter: 'https://twitter.com/charmingbrenda',
    website: '',
  },
  {
    name: 'Rita-Maria Oladokun',
    image: ritaMaria,
    description: 'Fullstack Engineer',
    linkedin: 'https://www.linkedin.com/in/rita-maria-oladokun-346784194/',
    github: 'https://github.com/Riri202',
    twitter: 'https://twitter.com/greatbigriri',
    website: '',
  },
  {
    name: 'Abiola Bakare',
    image: abiolaBakare,
    description: 'Fullstack Developer',
    linkedin: 'https://www.linkedin.com/in/iamstarcode',
    github: 'https://github.com/iamstarcode',
    twitter: 'https://twitter.com/iamstarcode',
    website: '',
  },
  {
    name: 'José Nriagu',
    image: joseNriagu,
    description: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/josemarianriagu/',
    github: 'https://github.com/josenriagu',
    twitter: 'https://twitter.com/josenriagu',
    website: 'https://josemarianriagu.com/',
  },
  {
    name: 'Goodness Osumah',
    image: goodnessOsumah,
    description: 'Data Analyst',
    linkedin: 'https://www.linkedin.com/in/goodness-osumah/',
    github: '',
    twitter: '',
    website: '',
  },
]
