import React from 'react'
import {
  FAQs,
  Metadata,
  MissionAndVision,
  PageBanner,
  WhoWeAre,
} from '../components'

export const AboutPage: React.FC = () => {
  return (
    <>
      <Metadata
        title="About Us | Dot Campus"
        description="Learn more about Dot Campus, our mission and vision."
      />
      <PageBanner pageName="About" pageTitle="About Us" />
      <WhoWeAre />
      <MissionAndVision />
      <FAQs />
    </>
  )
}
