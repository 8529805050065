import React from 'react'
import {
  WebinarCatchUp,
  WebinarDetails,
  WebinarHero,
  WebinarPanelists,
  WebinarSpeaker,
} from '../components/webinar'
import { webinars } from '../constants'
import { pastWebinarDate } from '../utils/getPastWebinarDate'
import { Metadata } from '../components/common/Metadata'

export const WebinarPage: React.FC = () => {
  // Check if the webinar date is before today's date
  const isPastDate = pastWebinarDate(webinars[0].date)

  const currentWebinar = webinars[0]
  const pastWebinars = webinars.slice(1)

  return (
    <>
      <Metadata
        title="Webinar | Dot Campus"
        description="Learn tech insights with Dot Campus."
      />
      <WebinarHero currentWebinar={currentWebinar} isPastDate={isPastDate} />
      <WebinarDetails currentWebinar={currentWebinar} isPastDate={isPastDate} />
      <WebinarSpeaker currentWebinar={currentWebinar} isPastDate={isPastDate} />
      <WebinarPanelists
        currentWebinar={currentWebinar}
        isPastDate={isPastDate}
      />
      <WebinarCatchUp webinars={pastWebinars} />
    </>
  )
}
