// -------------------------------------------------------------------------- //
// Application Styling
// -------------------------------------------------------------------------- //

import { shapeToString } from '../utils'

export const APPLICATION_MAX_WIDTH = '1412px'
export const GOOGLE_SHEET_FORM_SCRIPT_URL = shapeToString(
  process.env.REACT_APP_GOOGLE_SHEET_FORM_SCRIPT_URL,
)
export const ZOHO_REGISTRATION_WEBHOOK_URL = shapeToString(
  process.env.REACT_APP_ZOHO_REGISTRATION_WEBHOOK_URL,
)
// paystack public key
export const PAYSTACK_PUBLIC_KEY = shapeToString(
  process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
)
// flutterwave public key
export const FLUTTERWAVE_PUBLIC_KEY = shapeToString(
  process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
)

export const HAS_SUBMITTED_APPLICATION_FORM = 'HAS_SUBMITTED_APPLICATION_FORM'
export const DEVELOPER_APPLICATION_FORM_URL = '/register'

export const DEVELOPER_APPLICATION_REGISTRATION_URL = '/register'
export const WHATSAPP_CONTACT_URL = 'https://wa.me/2348189805679'
export const WHATSAPP_CONTACT_URL_FOR_CLASSES = 'https://wa.me/2349126336544'

export const WEB_DEVELOPER_APPLICATION_FORM_URL = '/programmes/web-development'
export const FRONTEND_ENGINEERING_APPLICATION_FORM_URL =
  '/programmes/frontend-engineering'
export const BACKEND_ENGINEERING_APPLICATION_FORM_URL =
  '/programmes/backend-engineering'

export const SUPPORT_EMAIL = 'support@dotcampus.co'

export const DCLC_BACKEND_API_URL = shapeToString(
  process.env.REACT_APP_DCLC_BACKEND_API_URL,
)

export const DCLC_BACKEND_API_KEY = shapeToString(
  process.env.REACT_APP_DCLC_BACKEND_API_KEY,
)

export * from './links'
export * from './about'
export * from './faqs'
export * from './countries'
export * from './webinars'
export * from './liveClasses'
