import summerCoding from '../assets/classes/summer-coding.webp'
import emberCoding from '../assets/classes/ember-coding.webp'

export interface LiveClass {
  title: string
  description: string
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  requirements: string[]
  fee: number
  learningPoints: string[]
  classDays: string[]
  edition?: string
  videoId?: string
  flyer?: string
  isMain: boolean
  duration: string
  paymentFrequency: string
}

const liveClasses: LiveClass[] = [
  {
    title: 'Online Coding for Kids',
    edition: "'Ember Edition",
    description:
      'Learn coding in a fun and interactive way during the Ember season.',
    startDate: 'September 17, 2024',
    endDate: 'December 15, 2024',
    startTime: '05:30 PM',
    endTime: '07:00 PM',
    classDays: ['Tuesdays', 'Wednesdays', 'Thursdays'],
    requirements: ['8 - 17 years', 'Laptop', 'good internet connection'],
    fee: 50000,
    learningPoints: [
      'Introduction to coding',
      'HTML',
      'CSS and JS basics',
      'Building web pages',
      'Problem solving',
      'Building games with Scratch',
    ],
    flyer: emberCoding,
    isMain: true,
    duration: '3 months',
    paymentFrequency: 'monthly',
  },
  {
    title: 'Online Coding for Kids',
    edition: 'Summer Edition',
    description: 'Learn coding in a fun and interactive way during the summer.',
    startDate: 'August 1, 2024',
    endDate: 'August 28, 2024',
    startTime: '04:00 PM',
    endTime: '06:00 PM',
    classDays: ['Monday', 'Wednesday', 'Thursday'],
    requirements: ['8 - 15 years', 'Laptop', 'good internet connection'],
    fee: 50000,
    learningPoints: [
      'Introduction to coding',
      'HTML',
      'CSS and JS basics',
      'Building web pages',
      'Problem solving',
      'Game development with Scratch',
    ],
    flyer: summerCoding,
    isMain: false,
    duration: '4 weeks',
    paymentFrequency: 'once',
  },
]

export default liveClasses
