/* eslint-disable react/jsx-props-no-spreading */

import { SelectField, SelectFieldProps } from '@chakra-ui/react'
import { FormFieldWrapper, FormFieldWrapperProps } from './FormFieldWrapper'

export type SelectProps = SelectFieldProps &
  FormFieldWrapperProps & {
    choices: SelectOptions
  }

export interface SelectOptionObject {
  label: string
  value?: string
  disabled?: boolean
  selected?: boolean
}

export type SelectOptionItem = SelectOptionObject
export type SelectOptions = SelectOptionItem[]

function Option({
  disabled,
  label,
  value,
  selected,
}: SelectOptionObject): JSX.Element {
  return (
    <option value={value} disabled={disabled} selected={selected}>
      {label}
    </option>
  )
}

/**
 * A generic `<Select />` element used in forms throughout the application.
 *
 * Handles the rendering of the input in both happy and error state.
 * If passed `props.error` as a string, the dropdown will turn red and the error
 * text will be displayed below it.
 */
export function Select(props: SelectProps): JSX.Element {
  const { name, autoComplete, choices, isRequired, ...rest } = props

  return (
    <FormFieldWrapper isRequired={isRequired} {...props}>
      <SelectField
        width="full"
        data-testid={`${name}-select`}
        {...rest}
        id={name}
        autoComplete={autoComplete ?? 'on'}
        backgroundColor="transparent"
        borderRadius="3"
        fontSize="lg"
        name={name}
        outline="none"
        padding="1"
        paddingTop="0.5"
        color={props.color ?? 'gray.700'}
      >
        {choices.map(options => (
          <Option key={options.label} {...options} />
        ))}
      </SelectField>
    </FormFieldWrapper>
  )
}
