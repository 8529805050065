export function pastWebinarDate(date: string): boolean {
  const webinarDate = new Date(date)
  // Get today's date
  const today = new Date()

  // webinar ends by 9 pm hence the time is set to 21 hours
  const webinarEndTime = 21 * 60 * 60 * 1000

  // Check whether the webinar time is before the current time
  return webinarDate.getTime() + webinarEndTime < today.getTime()
}
