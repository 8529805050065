export function shapeToArray<Collection extends unknown[]>(
  collection: Collection | undefined | null,
): Collection {
  if (!Array.isArray(collection)) return [] as unknown[] as Collection

  return collection
}

export function shapeToBoolean(bool: boolean | undefined | null): boolean {
  if (typeof bool !== 'boolean') return false

  return bool
}

export function shapeToNumber(num: number | undefined | null): number {
  if (typeof num !== 'number') return Number()

  return num
}

export function shapeToObject<Type extends object>(
  object: Type | undefined | null,
): Type {
  if (!object || typeof object !== 'object' || Array.isArray(object))
    return {} as Type

  return object
}

export function shapeToString(value: string | undefined | null): string {
  if (typeof value !== 'string') return ''

  return value
}
