import React from 'react'
import { Outlet } from 'react-router-dom'

import { BaseLayout } from './BaseLayout'
import { LayoutProps } from './types'

export const PublicFormLayout: React.FC<LayoutProps> = ({
  'data-testid': tid,
}) => {
  return (
    <BaseLayout fullWidth data-testid={tid}>
      <Outlet />
    </BaseLayout>
  )
}
