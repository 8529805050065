import React, { PropsWithChildren, ReactElement } from 'react'
import theme from '../theme'
import { ChakraProvider } from '@chakra-ui/react'

export const Providers = ({ children }: PropsWithChildren): ReactElement => {
  return (
    <>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </>
  )
}
