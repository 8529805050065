export const missionAndVision = [
  {
    title: 'Our Mission',
    content:
      'To empower individuals across the world, especially in Africa by providing accessible and effective learning opportunities, guidance, mentorship, and resources, to enhance their prospects for personal and professional growth.',
  },
  {
    title: 'Our Vision',
    content:
      'To be a leading hub in Africa and the world, recognized for our unwavering commitment to excellence in education and mentorship. We envision a future where our graduates are at the forefront of innovation, driving advancements, and contributing to the growth and prosperity of their communities and the world.',
  },
]
