import { ComponentStyleConfig } from '@chakra-ui/react'

export const Button: ComponentStyleConfig = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    none: {},
    primary: {
      _focus: { backgroundColor: 'primary.900' },
      _hover: { backgroundColor: 'primary.900' },
      backgroundColor: 'primary.500',
      borderRadius: '10',
      color: 'white',
      fontWeight: '600',
      minWidth: '165px',
      paddingX: '6',
      paddingY: '3',
      textAlign: 'center',
    },
    secondary: {
      _focus: {
        backgroundImage:
          'linear-gradient(to left, #FFC719 0%, #eb662d 51%, #FF2200 100%)',
      },
      _hover: {
        backgroundImage:
          'linear-gradient(to left, #FFC719 0%, #eb662d 51%, #FF2200 100%)',
      },
      _disabled: {
        backgroundColor: 'gray.400',
        cursor: 'not-allowed',
      },
      backgroundImage:
        'linear-gradient(to right, #FFC719 0%, #eb662d 51%, #FF2200 100%)',
      borderRadius: '10',
      color: 'gray.100',
      transition: 'background-image 0.5s',
      fontWeight: '600',
      minWidth: '165px',
      paddingX: '6',
      paddingY: '3',
      textAlign: 'center',
    },
    muted: {
      _focus: { backgroundColor: 'gray.500' },
      _hover: { backgroundColor: 'gray.500' },
      backgroundColor: 'gray.300',
      borderRadius: '10',
      fontWeight: '600',
      minWidth: '165px',
      paddingX: '6',
      paddingY: '3',
      textAlign: 'center',
    },
    link: {
      _focus: { color: 'primary.700' },
      _hover: { color: 'primary.700' },
      display: 'inline',
      color: 'primary.500',
      fontWeight: '500',
      minWidth: '165px',
      paddingY: '3',
      textAlign: 'left',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    outlineSecondary: {
      _focus: { outline: '1px solid' },
      _hover: { outline: '1px solid' },
      borderColor: 'orange.700',
      outlineColor: 'orange.700',
      color: 'orange.700',
      border: '2px solid',
      textAlign: 'center',
      borderRadius: '10',
      fontWeight: '600',
      minWidth: '165px',
      paddingX: '6',
      paddingY: '3',
    },
    outline: {
      _focus: {
        borderColor: 'orange.700',
        color: 'orange.700',
        fontWeight: '600',
      },
      _hover: {
        borderColor: 'orange.700',
        color: 'orange.700',
        fontWeight: '600',
      },
      borderColor: 'gray.700',
      color: 'gray.700',
      border: '2px solid',
      textAlign: 'center',
      borderRadius: '10',
      minWidth: '165px',
      paddingX: '6',
      paddingY: '3',
    },
    invertedPrimary: {
      _focus: { backgroundColor: 'gray.300' },
      _hover: { backgroundColor: 'primary.900', color: 'gray.100' },
      color: 'primary.500',
      background: 'gray.100',
      textAlign: 'center',
      borderRadius: '10',
      fontWeight: '600',
      paddingX: '7',
      paddingY: '3',
    },
    invertedSecondary: {
      _focus: { backgroundColor: 'gray.300' },
      _hover: { backgroundColor: 'orange.900', color: 'gray.100' },
      color: 'orange.700',
      background: 'gray.100',
      textAlign: 'center',
      borderRadius: '10',
      fontWeight: '600',
      paddingX: '6',
      paddingY: '3',
    },
  },
}
