import { DCLC_BACKEND_API_KEY, DCLC_BACKEND_API_URL } from '../../constants'
import { RecommendationResultType } from '../../types'

export async function handleRecommendationRequest(values: {
  learningInterest: string
  purpose: string
  codingExperienceLevel: string
}): Promise<RecommendationResultType | null> {
  const errorResult = {
    learningInterest: 'Unknown',
    deliveryMode: 'N/A',
    explanationForDeliveryMode:
      'An error occurred while processing your request. Please try again later.',
    explanationForLearningInterest: 'N/A',
  }
  try {
    const payload = JSON.stringify(values)

    const response = await fetch(`${DCLC_BACKEND_API_URL}/recommendations`, {
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${DCLC_BACKEND_API_KEY}`,
      },
    })

    const result = await response.json()

    if (result.message === 'success') {
      return result.data
    } else {
      return errorResult
    }
  } catch (error) {
    console.error(error)
    return errorResult
  }
}
