import React from 'react'
import { Box, Text, Heading } from '@chakra-ui/react'
import { WebinarCard } from './common/WebinarCard'
import { APPLICATION_MAX_WIDTH, Webinar } from '../../constants'

export const WebinarCatchUp = ({
  webinars,
}: {
  webinars: Webinar[]
}): React.ReactElement => {
  return (
    <Box
      maxWidth={APPLICATION_MAX_WIDTH}
      width="100%"
      my="30"
      mx="auto"
      padding={{ base: '5', md: '10' }}
      data-testid="webinar-catchup"
    >
      <Box>
        <Box>
          <Heading as="h4">Catch Up on What You Missed</Heading>
          <Text fontSize={{ base: 'sm', md: 'md' }} fontFamily={'heading'}>
            Watch the Full Webinar Sessions On-Demand
          </Text>
        </Box>
        <Box
          display="flex"
          gap={5}
          mt="10"
          width={'100%'}
          overflowX={{ base: 'scroll', xl: 'scroll' }}
        >
          {webinars.map(webinar => (
            <WebinarCard key={webinar.title} webinar={webinar} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}
