import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react'
import React from 'react'
import {
  APPLICATION_MAX_WIDTH,
  LiveClass,
  WHATSAPP_CONTACT_URL_FOR_CLASSES,
} from '../../constants'
import checklistIcon from '../../assets/checklist-icon.png'
import { Link } from '../common'

export const LiveClassDetails = ({
  ongoingClass,
  isPastDate,
}: {
  ongoingClass: LiveClass
  isPastDate: boolean
}): React.ReactElement => {
  return (
    <Box
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      data-testid="live-class-details"
      width={APPLICATION_MAX_WIDTH}
      marginX="auto"
      padding={{ base: '5', md: '10' }}
    >
      {isPastDate ? (
        <Box>
          <Heading as="h4">
            Contact us on WhatsApp to find out about our next live classes
          </Heading>
          <Link
            variant="button"
            to={WHATSAPP_CONTACT_URL_FOR_CLASSES}
            fontSize="md"
            border="2px solid"
            marginX="auto"
            _groupHover={{ borderColor: 'gray.100' }}
          >
            Start WhatsApp Chat
          </Link>
        </Box>
      ) : (
        <>
          <Box width={{ base: '100%', md: '40%' }} mb="5">
            <Box mb="5">
              <Heading as="h4">Requirements</Heading>
              <Text mt="3">
                Tuition Fee:{' '}
                <b>
                  {Intl.NumberFormat('en-ng', {
                    style: 'currency',
                    currency: 'NGN',
                  }).format(ongoingClass.fee)}{' '}
                  (paid {ongoingClass.paymentFrequency}){' '}
                </b>
              </Text>
              <Text mt="3">
                Age: <b>{ongoingClass.requirements[0]}</b>
              </Text>
              <Text mt="3">
                {ongoingClass.requirements[1]} & {ongoingClass.requirements[2]}
              </Text>
            </Box>

            <Box my={5}>
              <Heading as="h4">What will be taught</Heading>
              <Box my={4} bgColor={'#F0F6FF'} p={5}>
                {ongoingClass.learningPoints.map(point => (
                  <Flex key={point}>
                    <Image
                      src={checklistIcon}
                      alt="Checklist Icon"
                      height={{ base: '20px', md: '20px' }}
                      width={{ base: '20px', md: '20px' }}
                    />
                    <Text
                      color="gray.900"
                      mb="4"
                      ml="4"
                      textAlign={'left'}
                      fontSize={{ base: 'sm', lg: 'md' }}
                      fontFamily={'heading'}
                    >
                      {point}
                    </Text>
                  </Flex>
                ))}
              </Box>
            </Box>
            <Link
              variant="button"
              to={WHATSAPP_CONTACT_URL_FOR_CLASSES}
              fontSize="md"
              border="2px solid"
              marginX="auto"
              _groupHover={{ borderColor: 'gray.100' }}
            >
              Register Now via WhatsApp
            </Link>
          </Box>
          <Box width={{ base: '100%', md: '60%' }}>
            <Image
              src={ongoingClass.flyer}
              alt="Live Class Flyer"
              width={{ base: '100%', md: '500px' }}
              objectFit="cover"
            />
          </Box>
        </>
      )}
    </Box>
  )
}
