import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const Radio: ComponentMultiStyleConfig = {
  parts: ['container', 'control', 'icon', 'label', 'input'],
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    primary: {
      container: {
        display: 'flex',
        alignItems: 'baseline',
        mb: '4',
        bg: 'rgba(250, 107, 5, 0.1)',
        px: '4',
        py: '2',
        borderRadius: '5',
        border: '1px solid',
        borderColor: 'orange.700',
        _hover: {
          boxShadow: '0 0 1px 1px #eb662d',
        },
        _focus: {
          boxShadow: '0 0 1px 1px #eb662d',
        },
      },
      label: {
        display: 'inline-block',
        _checked: {
          marginLeft: '11px',
        },
      },
      control: {
        position: 'relative',
        top: '2px',
        border: '1px solid',
        borderColor: 'gray.300',
        width: '4',
        height: '4',
        borderRadius: '50%',
        _checked: {
          position: 'unset',
          outline: '1px solid',
          outlineColor: 'gray.300',
          width: '10px',
          height: '10px',
          marginLeft: '3px',
          borderRadius: '50%',
          outlineOffset: '3px',
          background: 'orange.900',
        },
        _focus: {
          boxShadow: 'inset 0 0 2px 2px #eb662d',
        },
        _hover: {
          boxShadow: 'inset 0 0 2px 2px #eb662d',
        },
      },
    },
  },
}
