import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const Checkbox: ComponentMultiStyleConfig = {
  parts: ['container', 'control', 'icon', 'label', 'input'],
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    primary: {
      control: {
        border: '2px solid',
        borderColor: 'gray.300',
        width: '5',
        height: '5',
      },
      label: {
        color: 'gray.700',
      },
    },
  },
}
