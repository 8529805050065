import React from 'react'
import { Helmet } from 'react-helmet'

export const Metadata = ({
  title,
  description,
}: {
  title: string
  description: string
}): React.ReactElement => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}
