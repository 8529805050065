import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { Mentor } from '../../../constants/mentors'
import { SocialIconLink } from '../SocialIconLink'
import linkedInIcon from '../../../assets/linkedin-icon.svg'
import twitterIcon from '../../../assets/x-icon.svg'
import githubIcon from '../../../assets/github-mark.svg'

export const MentorCard = ({
  name,
  image,
  linkedin,
  description,
  twitter,
  github,
}: Mentor): React.ReactElement => {
  return (
    <Flex
      height="377px"
      width="260px"
      background={`linear-gradient(359.84deg, rgba(30, 30, 30, 0.96) 19.58%, rgba(0, 0, 0, 0) 64.66%), url(${image})`}
      backgroundSize="contain"
      alignItems="flex-end"
    >
      <Box bg="gray.700" width="100%" padding="2" textAlign="center">
        <Heading as="h3" fontSize="md" color="gray.200" fontWeight="semibold">
          {name}
        </Heading>
        <Text color="gray.200">{description}</Text>
        <Flex justifyContent="center" mt="2">
          {linkedin && (
            <SocialIconLink
              iconSrc={linkedInIcon}
              url={linkedin}
              name={`${name} linkedin profile`}
            />
          )}
          {github && (
            <SocialIconLink
              iconSrc={githubIcon}
              url={github}
              name={`${name} github profile`}
              width="22px"
            />
          )}
          {twitter && (
            <SocialIconLink
              iconSrc={twitterIcon}
              url={twitter}
              name={`${name} twitter profile`}
            />
          )}
        </Flex>
      </Box>
    </Flex>
  )
}
