import React from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { FullColourLogo } from '..'
import { APPLICATION_MAX_WIDTH } from '../../../constants'
import { NavbarLinks } from './NavbarLinks'
import { MobileNavbarDrawer } from './MobileNavbarDrawer'

export const Navbar: React.FC = () => {
  return (
    <Box width="full">
      <Flex
        as="nav"
        alignItems="center"
        justifyContent="space-between"
        padding="5"
        data-testid="navbar"
        maxWidth={APPLICATION_MAX_WIDTH}
        marginX="auto"
      >
        <Flex alignItems="center">
          <FullColourLogo />
        </Flex>
        <MobileNavbarDrawer />
        <Box
          display={{ base: 'none', md: 'block' }}
          data-testid="desktop-nav-links"
        >
          <NavbarLinks />
        </Box>
      </Flex>
    </Box>
  )
}
