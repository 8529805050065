import {
  Box,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import React from 'react'
import happyGirl from '../../assets/smiling_girl.png'
import { CourseComponentProps } from '../../types'
import { APPLICATION_MAX_WIDTH } from '../../constants'

export const ProgrammeFooter: React.FC<CourseComponentProps> = ({
  programChoice,
}) => {
  return (
    <Box
      width={'100%'}
      bgGradient="linear-gradient(to-l, #FFC719 0%, #eb662d 51%, #FF2200 100%)"
      p={6}
      mx="auto"
      data-testid="programme-footer"
    >
      <Flex
        width={{ base: '100%', md: '85%' }}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        gap={5}
        mx={'auto'}
        maxWidth={APPLICATION_MAX_WIDTH}
      >
        <Box>
          <Text
            color="gray.100"
            fontWeight={'bold'}
            fontSize={{ base: 'xl', lg: '2xl' }}
          >
            Career Prospects
          </Text>
          <Text
            color="gray.100"
            data-testid="programme-prospect"
            fontSize={{ base: 'xs', lg: 'md' }}
          >
            Prepare to become a {programChoice.career}
          </Text>

          <UnorderedList color="gray.100" mt="30px" fontWeight="bold">
            <ListItem>
              Showcase your skills through portfolio-ready projects
            </ListItem>
            <ListItem>Receive your Diploma Certificate</ListItem>
            <ListItem>
              Become eligible for sought-after positions as a{' '}
              {programChoice.career}
            </ListItem>
          </UnorderedList>
        </Box>
        <Box>
          <Image
            src={happyGirl}
            alt="happy girl"
            width="60%"
            mt={{ md: '-40px' }}
            mb={'10px'}
            mx="auto"
          />
        </Box>
      </Flex>
    </Box>
  )
}
