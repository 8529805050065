import { extendTheme } from '@chakra-ui/react'
import * as components from './components'

import breakpoints from './breakpoints'
import colors from './colors'
import config from './config'
import styles from './styles'
import { fonts, fontSizes } from './fonts'

export default extendTheme({
  breakpoints,
  colors,
  components,
  config,
  fonts,
  fontSizes,
  styles,
})
