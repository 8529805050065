import {
  Box,
  Flex,
  Heading,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import React, { useState } from 'react'
import { valueBasedPricing } from '../constants/pricing'
import {
  PageBanner,
  ScholarshipAvailable,
  CostCard,
  Metadata,
} from '../components'
import { APPLICATION_MAX_WIDTH } from '../constants'
import { Switch } from '../components/Input/Switch'

export const PricingPage: React.FC = () => {
  const { onToggle, isOpen: isUSD } = useDisclosure()
  const [paymentFrequency, setPaymentFrequency] = useState('monthly')
  return (
    <>
      <Metadata
        title="Pricing | Dot Campus"
        description="Learn about the pricing for our programs at Dot Campus."
      />
      <PageBanner pageName="Pricing" pageTitle="Pricing" />
      <Box
        as="section"
        maxWidth={APPLICATION_MAX_WIDTH}
        marginX="auto"
        padding="7"
        textAlign="center"
        marginY="10"
        data-testid="cost-section"
      >
        <Heading as="h2">At What Cost?</Heading>
        <Text color="gray.700">
          You don&apos;t have to burn through your life savings. Our fees are
          very affordable and super flexible.
        </Text>

        <Tabs variant="soft-rounded" colorScheme="primary.500" my={5}>
          <TabList
            boxShadow="base"
            rounded="full"
            width="max-content"
            mx={'auto'}
            bgColor={'#fff'}
          >
            <Tab
              _selected={{ color: 'white', bg: 'primary.500' }}
              onClick={(): void => setPaymentFrequency('monthly')}
              rounded="full"
              px={5}
              py={3}
            >
              Monthly
            </Tab>
            <Tab
              _selected={{ color: 'white', bg: 'primary.500' }}
              onClick={(): void => setPaymentFrequency('quarterly')}
              px={5}
              py={4}
              rounded="full"
            >
              Quarterly
            </Tab>
            <Tab
              _selected={{ color: 'white', bg: 'primary.500' }}
              onClick={(): void => setPaymentFrequency('upfront')}
              px={5}
              py={4}
              rounded="full"
            >
              Upfront
            </Tab>
          </TabList>
        </Tabs>

        <Flex
          justifyContent="center"
          flexWrap={{ md: 'wrap', lg: 'nowrap' }}
          gap="4"
          mt={6}
        >
          <Text fontWeight="600">Naira (₦)</Text>{' '}
          <Switch isToggled={isUSD} onToggle={onToggle} />{' '}
          <Text fontWeight="600"> USD ($)</Text>
        </Flex>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          flexWrap={{ md: 'wrap', lg: 'nowrap' }}
          gap="4"
          mt={14}
        >
          {valueBasedPricing[
            paymentFrequency as keyof typeof valueBasedPricing
          ].map(pricingItem => (
            <CostCard
              key={pricingItem.title}
              title={pricingItem.title}
              description={pricingItem.isRecommended ? 'Recommended' : ''}
              price={isUSD ? pricingItem.price.dollar : pricingItem.price.naira}
              duration={pricingItem.duration}
              link={pricingItem.link}
              benefits={pricingItem.benefits}
              savings={
                isUSD ? pricingItem.savings.dollar : pricingItem.savings.naira
              }
            />
          ))}
        </Flex>
        <ScholarshipAvailable />
      </Box>
    </>
  )
}

export default PricingPage
