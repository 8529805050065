import { Heading, Box, ListItem, UnorderedList } from '@chakra-ui/react'

import React from 'react'
import { CourseType } from '../../types'
import { APPLICATION_MAX_WIDTH } from '../../constants'
interface CourseComponentProps {
  programChoice: CourseType
}

export const Overview: React.FC<CourseComponentProps> = ({ programChoice }) => {
  return (
    <Box
      maxWidth={APPLICATION_MAX_WIDTH}
      width={{ base: '90%' }}
      mx={'auto'}
      my={'50px'}
    >
      <Heading as="h2" mb="5">
        Programme Overview
      </Heading>
      <Box bgColor={'#F0F6FF'}>
        <UnorderedList
          role="list"
          pl={6}
          py={6}
          fontSize={{ base: 'md', lg: 'lg' }}
        >
          {programChoice.description.map(learningOutcome => (
            <ListItem color="gray.800" mb={3} key={learningOutcome}>
              {learningOutcome}
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
    </Box>
  )
}
