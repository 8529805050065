import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { CallToAction, Navbar, Footer } from '../components'

import { BaseLayout } from './BaseLayout'
import { LayoutProps } from './types'
export const PublicLayout: React.FC<LayoutProps> = ({ 'data-testid': tid }) => {
  const location = useLocation()

  const CTA: React.FC = () => {
    if (location.pathname.match(/engineering/gi)) {
      return null
    } else {
      return <CallToAction />
    }
  }
  return (
    <BaseLayout fullWidth data-testid={tid}>
      <Navbar />
      <Outlet />
      <CTA />
      <Footer />
    </BaseLayout>
  )
}
