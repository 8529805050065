import React from 'react'
import { Box, Center, Heading } from '@chakra-ui/react'
import programHeroImage from '../../assets/program-hero.png'
import { CourseComponentProps } from '../../types'

export const ProgramHero: React.FC<CourseComponentProps> = ({
  programChoice,
}) => {
  return (
    <>
      <Box
        position="relative"
        h={{ base: '300px', md: '500px' }}
        bgImage={programChoice.image || programHeroImage}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        left={0}
        right={0}
        width="100vw"
        maxWidth="100%"
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          bg="black"
          opacity={0.7}
          bgBlendMode="multiply"
        />
        <Center
          position="relative"
          zIndex={1}
          textAlign="center"
          display="flex"
          justifyContent="center"
          minH={80}
        >
          <Heading
            as="h1"
            bgGradient="linear(to-r, #FFC719, #FF2200)"
            bgClip="text"
            textAlign="center"
            fontWeight="extrabold"
            fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
          >
            {programChoice.title}
          </Heading>
        </Center>
      </Box>
    </>
  )
}
