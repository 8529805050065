import dataAnalysis from '../assets/data-analysis.webp'
import productDesign from '../assets/product-design.webp'

export const courses = {
  dataAnalysis: {
    title: 'DATA ANALYSIS',
    career: 'Data Analyst',
    image: dataAnalysis,
    description: [
      'Our comprehensive curriculum covers Python, SQL, Data Analysis, Data Visualization, and more.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced data analyst, tailored to your pace and needs.',
      'Supplemental resources like project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: [
      'Computer science fundamentals',
      'Soft-skills: Communication',
      'Resume and portfolio building',
      'Git (Version Control System)',
      'Excel',
      'Data Visualization',
      'Python',
      'Pandas, Numpy, Matplotlib, etc.',
      'SQL',
      'Statistics (with Python and Excel)',
      'Soft-skills: Professionalism',
      'Power BI (Bonus)',
      'Capstone Project',
    ],
  },
  web: {
    title: 'WEB DEVELOPMENT',
    career: 'Web Developer',
    description: [
      'Our comprehensive curriculum covers HTML, CSS, JavaScript, Data Structures and Algorithms, Frontend frameworks, and more.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced developers, tailored to your pace and needs.',
      'Supplemental resources like coding challenges, project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: [
      'Computer science fundamentals',
      'Web development fundamentals',
      'Git (Version Control System)',
      'HTML',
      'CSS',
      'JavaScript',
      'Algorithms and data structures',
      'Browser extensions',
      'Software engineering fundamentals',
      'Soft-skills: Communication',
      'Soft-skills: Professionalism',
      'Resume and portfolio building',
      'Open source contribution',
    ],
  },
  frontend: {
    title: 'FRONTEND ENGINEERING',
    career: 'Frontend Engineer',
    description: [
      'Learn the core principles of software engineering, delve into ReactJS, and beyond. Craft responsive and secure web interfaces, champion web security, optimize performance, and perfect backend communication.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced developers, tailored to your pace and needs.',
      'Supplemental resources like coding challenges, project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: [
      'Web development refresher',
      'Git (Version Control System)',
      'Browser extensions',
      'UI/UX design fundamentals',
      'Software Engineering fundamentals',
      'Advanced Data Structures and Algorithms',
      'ReactJS or VueJS ⚛️',
      'Web security and Authentication',
      'Web performance',
      'Web accessibility (a11y)',
      'Object Oriented Programming (OOP) in JS',
      'Software application testing ',
      'Soft-skills: Communication',
      'Soft-skills: Professionalism',
      'Resume and portfolio building',
      'Open source contribution',
    ],
  },
  backend: {
    title: 'BACKEND ENGINEERING',
    career: 'Backend Engineer',
    description: [
      'From mastering JavaScript/Node.js, Python, Ruby, Java and frameworks like Django, Flask, Ruby on Rails, Spring boot OR NestJS/Express, to learning database design, API development, security, scalability, and more.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced developers, tailored to your pace and needs.',
      'Supplemental resources like coding challenges, project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: [
      'Software Engineering fundamentals',
      'Git (Version Control System)',
      'Advanced Data Structures and Algorithms',
      'Node.js (Express) OR Python/Django OR Java OR C# (.NET)',
      'Object Oriented Programming (OOP)',
      'REST API Development',
      'Database Design and Management',
      'Web security and Authentication',
      'Scalability and Performance Optimization',
      'Integration with Third-party Services',
      'Soft-skills: Communication',
      'Soft-skills: Professionalism',
      'Resume and portfolio building',
      'Open source contribution',
    ],
  },
  productDesign: {
    title: 'PRODUCT DESIGN',
    career: 'Product Designer',
    image: productDesign,
    description: [
      'Our comprehensive curriculum covers UI/UX design, design thinking, web design, mobile app design, prototyping, user research, and more.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced designers, tailored to your pace and needs.',
      'Supplemental resources like project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: [
      'User research',
      'Design thinking',
      'UI/UX design fundamentals',
      'Wireframing and Prototyping',
      'Visual Design principles',
      'Mobile app design',
      'Web design',
      'Soft-skills: Communication',
      'Soft-skills: Professionalism',
      'Resume and portfolio building',
    ],
  },
}

export const learningInterests = {
  // core programmes (6+ months)
  web: 'web development',
  backend: 'backend engineering',
  frontend: 'frontend engineering',
  fullstack: 'fullstack engineering',
  dataAnalysis: 'data analysis',
  productDesign: 'product design',

  // short courses (1 - 3 months)
  reactFrontend: 'react frontend',
  nodeBackend: 'node backend',
  pythonBackend: 'python backend',
  javaBackend: 'java backend',
  softwareEngineeringEssentials: 'software engineering essentials',
  excelDataAnalysis: 'data analysis with excel',
  pythonDataAnalysis: 'data analysis with python',
}
