import React from 'react'

import { Metadata } from '../components/common/Metadata'
import {
  LiveClassDetails,
  LiveClassHero,
  PastLiveClasses,
} from '../components/live-classes'
import liveClasses from '../constants/liveClasses'
import { pastWebinarDate } from '../utils/getPastWebinarDate'

export const LiveClassesPage: React.FC = () => {
  const pastClasses = liveClasses.filter(liveClass =>
    pastWebinarDate(liveClass.endDate),
  )
  const mainClass = liveClasses.filter(liveClass => liveClass.isMain)[0]
  const isPastDate = pastWebinarDate(mainClass.endDate)

  return (
    <>
      <Metadata
        title="Live Classes | Dot Campus"
        description="Ongoing and upcoming live online classes."
      />
      <LiveClassHero mainClass={mainClass} isPastDate={isPastDate} />
      <LiveClassDetails ongoingClass={mainClass} isPastDate={isPastDate} />
      <PastLiveClasses liveClasses={pastClasses} />
    </>
  )
}
