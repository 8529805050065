import React from 'react'
import { Box, Text, Heading, Divider } from '@chakra-ui/react'
import webinarHeroImage from '../../assets/webinarHero.png'
import { APPLICATION_MAX_WIDTH, Webinar } from '../../constants'

export const WebinarHero = ({
  currentWebinar,
  isPastDate,
}: {
  currentWebinar: Webinar
  isPastDate: boolean
}): React.ReactElement => {
  return (
    <Box
      bgImage={webinarHeroImage}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      width="100%"
      data-testid="webinar-hero"
    >
      <Box
        maxWidth={APPLICATION_MAX_WIDTH}
        width="100%"
        marginX="auto"
        padding={{ base: '5', md: '10' }}
      >
        {!isPastDate && (
          <>
            <Heading
              as="h4"
              fontWeight="extrabold"
              fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
            >
              Webinar
            </Heading>
            <Box
              display="flex"
              flexDirection={{ base: 'column', md: 'row' }}
              gap={3}
              my={4}
            >
              <Text
                bgColor="yellow.300"
                fontWeight="bold"
                fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
                width={'fit-content'}
                paddingX={3}
              >
                Upcoming Live
              </Text>
              <Box display="flex" flexDirection="row" gap={3}>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
                  width={'fit-content'}
                >
                  {currentWebinar.date}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
                  width={'fit-content'}
                >
                  {currentWebinar.time}
                </Text>
              </Box>
            </Box>
          </>
        )}

        <Heading
          as="h1"
          fontWeight="extrabold"
          fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
          width={{ base: '100%', md: '70%' }}
          marginY={{ base: '3%', md: '5%' }}
        >
          {!isPastDate ? currentWebinar.title : 'No Upcoming Webinar'}
        </Heading>

        {!isPastDate && (
          <>
            <Box
              display="flex"
              flexDirection="row"
              marginBottom={{ base: '3%', md: '5%' }}
              gap={3}
            >
              <Text
                fontWeight="bold"
                fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
                width={'fit-content'}
              >
                {currentWebinar.speaker}
              </Text>
              <Divider
                border={'1px solid red'}
                borderTopWidth={{ base: '23px', md: '29px', lg: '37px' }}
                orientation="vertical"
              />
              <Text
                fontWeight="bold"
                fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
                width={'fit-content'}
              >
                Google Meet
              </Text>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
