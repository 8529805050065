import React from 'react'
import { Heading, Box, Text, Flex, Image, Grid } from '@chakra-ui/react'
import checklistIcon from '../../assets/checklist-icon.png'
import { APPLICATION_MAX_WIDTH } from '../../constants'
import { CourseComponentProps } from '../../types'

export const Curriculum: React.FC<CourseComponentProps> = ({
  programChoice,
}) => {
  return (
    <Box
      maxWidth={APPLICATION_MAX_WIDTH}
      width={{ base: '90%' }}
      mx={'auto'}
      my={'50px'}
      data-testid="curriculum"
    >
      <Heading as="h2" mb="5">
        Curriculum
      </Heading>
      <Grid
        templateColumns={{
          base: 'unset',
          md: 'repeat(2, 1fr)',
          xl: 'repeat(3, 1fr)',
        }}
        gap={4}
        p={6}
        bgColor={'#F0F6FF'}
      >
        {programChoice.curriculum.map(curriculum => (
          <Flex key={curriculum}>
            <Image
              src={checklistIcon}
              alt="Checklist Icon"
              height={{ base: '20px' }}
              width={{ base: '20px' }}
            />
            <Text
              as="p"
              fontSize={{ base: 'md', md: 'lg' }}
              mb="3"
              ml={3}
              color="gray.800"
            >
              {curriculum}
            </Text>
          </Flex>
        ))}
      </Grid>
    </Box>
  )
}
