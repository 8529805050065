import { string } from 'yup'

const NAMES_MATCH_RE = /^([A-z]['-]?)+$/i

export const email = string()
  .required('Email is required.')
  .email('Please enter a valid email address.')
  // validate that email ends with a valid domain
  .matches(/@.*\./, 'Please enter a valid email address.')

export const firstName = string()
  .required('Your first name is required.')
  .trim()
  .matches(NAMES_MATCH_RE, 'Please enter a valid first name')

export const lastName = string()
  .required('Your last name is required.')
  .trim()
  .matches(NAMES_MATCH_RE, 'Please enter a valid last name')

// validate phone number
export const phoneNumber = string().required('Your phone number is required.')

export const learningInterest = string().required(
  'Your programme of Interest is required',
)
export const learningPreference = string().required(
  'Learning Preference is required',
)
export const paymentPlan = string().required(
  'Your payment preference is required',
)
export const preferredPaymentCurrency = string().required(
  'Your preferred payment currency is required',
)
export const countryOfResidence = string().required(
  'Your country of residence is required',
)
export const referredBy = string().required('How did you learn about us?')

// validate referral code. Must be 7 characters long
export const referralCode = string().min(
  4,
  'Referral code must be at least 4 characters long.',
)
