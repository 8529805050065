import React from 'react'
import { Box, Text, Heading } from '@chakra-ui/react'
import { LiveClassCard } from './common/LiveClassCard'
import { APPLICATION_MAX_WIDTH, LiveClass } from '../../constants'

export const PastLiveClasses = ({
  liveClasses,
}: {
  liveClasses: LiveClass[]
}): React.ReactElement => {
  return (
    <Box
      maxWidth={APPLICATION_MAX_WIDTH}
      width="100%"
      my="30"
      mx="auto"
      padding={{ base: '5', md: '10' }}
      data-testid="past-live-classes"
    >
      <Heading as="h4">Past Classes</Heading>
      <Text fontSize={{ base: 'sm', md: 'md' }} fontFamily={'heading'}>
        Some of our past live classes
      </Text>
      <Box display="flex" gap={5} mt="10" width={'100%'}>
        {liveClasses.map(liveClass => (
          <LiveClassCard
            key={`${liveClass.title} (${liveClass.edition})`}
            liveClass={liveClass}
          />
        ))}
      </Box>
    </Box>
  )
}
