/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react'
import { AnyFuncType } from '../../types'

export interface FormFieldWrapperProps {
  label: string
  name: string
  labelColor?: string
  error?: null | false | string
  isRequired?: boolean
  tooltipMessage?: string
  secondaryLabel?: string
  helper?: string
}

const toggles = (f: AnyFuncType) => (): void => f((prev: boolean) => !prev)

export const FormFieldWrapper: React.FC<
  FormFieldWrapperProps & { children: React.ReactNode }
> = ({
  children,
  error,
  isRequired,
  label,
  labelColor = 'primary.500',
  name,
  ...props
}) => {
  const color = error ? 'red.500' : 'primary.500'
  const [isInputFocused, setFocusState] = useState(false)

  return (
    <FormControl {...props} mb="4" isInvalid={!!error} isRequired={isRequired}>
      <Box
        backgroundColor="transparent"
        borderBottom={`${isInputFocused ? 3 : 1}px solid`}
        borderColor={color}
        minWidth="200px"
        onBlur={toggles(setFocusState)}
        onFocus={toggles(setFocusState)}
        py="1"
      >
        <Flex>
          <FormLabel
            color={labelColor}
            data-focused={isInputFocused ? true : undefined}
            fontSize="md"
            fontWeight="500"
            htmlFor={name}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {label}
          </FormLabel>
        </Flex>
        {children}
      </Box>
      <FormErrorMessage>
        <Text mt="1" variant="error">
          {error}
        </Text>
      </FormErrorMessage>
    </FormControl>
  )
}
