export const MENTOR_APPLICATION_FORM_URL = 'https://forms.gle/cdEn4KTnz4RDekcS8'

export const faqs = [
  {
    question: 'Do I need to have prior tech knowledge or experience to join?',
    answer:
      'No, you do not need to have prior tech knowledge or experience to join Dot Campus. We welcome anyone who is interested in learning to code and building a career in tech. Only basic computer skills are required.',
  },
  {
    question: 'What is "Self-Paced Learning"?',
    answer:
      'Self-Paced Learning is a learning program that provides you with a structured learning path, resources, community support and more. It is a great way to learn at your own pace. Self-Paced Learning is NOT a tutorial-based learning programme.',
  },
  {
    question:
      'What is the difference between "Self-Paced Learning" and "Mentorship?"',
    answer:
      '"Mentorship" is a program that includes Self-Paced Learning and also provides you with a mentor to guide you and help you learn effectively.',
  },
  {
    question: 'What will I gain from Mentorship?',
    answer:
      'In our mentorship, an industry-experienced mentor guides and supports you in developing the skills, knowledge, and sometimes career-building opportunities. It involves one-on-one interactions, reviews, and advice to help you grow in the field.',
  },
  {
    question: 'Are mentorship sessions conducted in person or online?',
    answer:
      'Most mentorship sessions are conducted online through video calls, screen sharing, and chat tools. This allows for flexibility and enables mentors and mentees from different locations to connect easily.',
  },
  {
    question: 'What should I expect from Dot Campus Mentorship Program?',
    answer:
      'In this mentorship program, you can expect guidance, feedback on your assignments and projects, advice on career development, and the opportunity to learn from an experienced professionals. It is a collaborative and supportive learning experience. We do not guarantee job placements or internships, but we provide necessary support for that.',
  },
  {
    question: 'Can I become a mentor with Dot Campus?',
    answer:
      'Yes, we welcome experienced programmers to become mentors on our platform. You can apply to become a mentor by filling out the application form. We will review your application and get back to you.',
    link: {
      text: 'Apply to become a mentor',
      href: MENTOR_APPLICATION_FORM_URL,
      isExternal: true,
    },
  },
  {
    question:
      'What programming languages and technologies are covered by mentors on Dot Campus?',
    answer:
      'Our mentors cover a wide range of programming languages and technologies, including but not limited to Python, JavaScript, Java, C++, web development, and more.',
  },
  {
    question: 'How much does it cost to join Dot Campus?',
    answer:
      'Please refer to our pricing page for more information on the cost of joining Dot Campus.',
    link: {
      text: 'View pricing',
      href: '/pricing',
      isExternal: false,
    },
  },
  {
    question: 'How do I join Dot Campus?',
    answer:
      'You can join Dot Campus by filling out the application form. We will review your application and get back to you.',
  },
]
