import { Image } from '@chakra-ui/react'
import { Link } from './Link'
import logo from '../../assets/full-colour-logo.png'

export const FullColourLogo: React.FC = () => {
  return (
    <Link
      to="/"
      variant="link"
      width="fit-content"
      data-testid="full-colour-logo"
    >
      <Image src={logo} alt="Dot Campus logo" width="161px" />
    </Link>
  )
}
