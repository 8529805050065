import { Box, Flex, Heading, Link, Image } from '@chakra-ui/react'
import React from 'react'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import checklistIcon from '../../assets/checklist-icon.png'
import { APPLICATION_MAX_WIDTH } from '../../constants'

export const Highlight: React.FC = () => {
  const benefitList = [
    '100% remote, online learning',
    'Gain more than 20 portfolio-ready projects as you learn',
    'Have access to a community of mentors and peers',
    'Gain a certificate of completion',
  ]
  return (
    <Flex
      bgColor="primary.500"
      width="100%"
      my="20"
      data-testid="highlight"
      justifyContent="center"
    >
      <Flex
        width={{ base: '90%' }}
        direction={{ base: 'column', md: 'row' }}
        bgColor="primary.500"
        mx="auto"
        py={6}
        justifyContent="space-between"
        alignItems={{ base: 'left', md: 'center' }}
        maxWidth={APPLICATION_MAX_WIDTH}
      >
        <Box my={4}>
          {benefitList.map(benefit => (
            <Flex key={benefit}>
              <Image
                src={checklistIcon}
                alt="Checklist Icon"
                height={{ base: '20px', md: '30px' }}
                width={{ base: '20px', md: '30px' }}
              />
              <Heading
                as="h3"
                color="gray.100"
                mb="4"
                ml="4"
                textAlign={'left'}
                fontSize={{ base: 'md', lg: '2xl' }}
              >
                {benefit}
              </Heading>
            </Flex>
          ))}
        </Box>

        <Link
          variant="buttonSecondary"
          as={ReactRouterDomLink}
          to="/register"
          fontSize={{ base: 'sm', lg: 'lg' }}
          width="fit-content"
          mx={{ base: 'auto', md: '0' }}
        >
          Register Now
        </Link>
      </Flex>
    </Flex>
  )
}
