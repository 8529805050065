import React from 'react'
import { APPLICATION_MAX_WIDTH, faqs } from '../../constants'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react'
import { Link } from '../common'

export const FAQs = (): React.ReactElement => {
  return (
    <Flex
      gap="5"
      maxWidth={APPLICATION_MAX_WIDTH}
      width={{ base: '100%', lg: '85%', xl: '70%' }}
      paddingY="10"
      paddingX="5"
      marginX="auto"
      flexDirection={{ base: 'column', md: 'row' }}
      justifyContent="center"
      alignItems="center"
      textAlign={{ base: 'center', md: 'left' }}
    >
      <Box width={{ base: '100%', md: '100%' }}>
        <Heading
          as="h2"
          fontWeight="bold"
          marginBottom="5"
          textAlign={'center'}
          id="faqs"
        >
          Frequently Asked Questions (FAQs)
        </Heading>
        <Accordion allowToggle width={{ base: '100%', md: '80%' }} mx={'auto'}>
          {faqs.map(item => (
            <AccordionItem key={item.question}>
              <Heading as="h3" fontWeight="bold" marginBottom="1" py={2}>
                <AccordionButton
                  px={1}
                  _expanded={{ bg: 'primary.500', color: 'gray.100' }}
                >
                  <Text
                    fontSize="lg"
                    flex="1"
                    textAlign="left"
                    color="inherit"
                    padding="2"
                  >
                    {item.question}
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>
              <AccordionPanel pb={4}>
                <Text>{item.answer}</Text>
                {item.link && (
                  <Link
                    to={item.link.href}
                    isExternal={item.link.isExternal}
                    fontSize="lg"
                  >
                    {item.link.text}
                  </Link>
                )}
              </AccordionPanel>
              <Divider color={'gray.400'} border={'1px solid'} />
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </Flex>
  )
}
