import { Text } from '@chakra-ui/react'

export const FormClosed: React.FC = () => {
  return (
    <Text
      color="gray.200"
      fontSize="2xl"
      textAlign="center"
      data-testid="application-closed"
    >
      Oops 😕, we are no longer accepting applications now. Please look out for
      our next cycle.
    </Text>
  )
}
