import {
  Flex,
  Hide,
  Show,
  Tab,
  TabList,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import React, { useState } from 'react'
import { CostCard, ScholarshipAvailable } from './Pricing'
import { valueBasedPricing } from '../../constants/pricing'
import { Switch } from '../Input/Switch'
import { Link } from './Link'

type PaymentFrequency = 'monthly' | 'quarterly' | 'upfront'

export const CostContent: React.FC = () => {
  const { onToggle, isOpen: isUSD } = useDisclosure()
  const [paymentFrequency, setPaymentFrequency] =
    useState<PaymentFrequency>('monthly')

  const frequency: PaymentFrequency[] = ['monthly', 'quarterly', 'upfront']
  return (
    <>
      <Tabs variant="soft-rounded" colorScheme="primary.500" my={5}>
        <TabList
          boxShadow="base"
          rounded="full"
          width="max-content"
          mx={'auto'}
          bgColor={'#fff'}
        >
          {frequency.map(item => (
            <Tab
              key={item}
              _selected={{ color: 'white', bg: 'primary.500' }}
              onClick={(): void => setPaymentFrequency(item)}
              rounded="full"
              px={5}
              py={3}
              textTransform="capitalize"
            >
              {item}
            </Tab>
          ))}
        </TabList>
      </Tabs>

      <Flex
        justifyContent="center"
        flexWrap={{ md: 'wrap', lg: 'nowrap' }}
        gap="4"
        mt={6}
      >
        <Text fontWeight="600">Naira (₦)</Text>{' '}
        <Switch isToggled={isUSD} onToggle={onToggle} />{' '}
        <Text fontWeight="600"> USD ($)</Text>
      </Flex>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        flexWrap={{ md: 'wrap', lg: 'nowrap' }}
        gap="4"
        mt={14}
      >
        <Hide below="md">
          {valueBasedPricing[
            paymentFrequency as keyof typeof valueBasedPricing
          ].map(pricingItem => (
            <CostCard
              key={pricingItem.title}
              title={pricingItem.title}
              description={pricingItem.isRecommended ? 'Recommended' : ''}
              price={isUSD ? pricingItem.price.dollar : pricingItem.price.naira}
              duration={pricingItem.duration}
              link={pricingItem.link}
              benefits={pricingItem.benefits}
              savings={
                isUSD ? pricingItem.savings.dollar : pricingItem.savings.naira
              }
            />
          ))}
        </Hide>
        <Show below="md">
          {valueBasedPricing[paymentFrequency as keyof typeof valueBasedPricing]
            .filter(pricingItem => pricingItem.isRecommended)
            .map(pricingItem => (
              <CostCard
                key={pricingItem.title}
                title={pricingItem.title}
                description={pricingItem.isRecommended ? 'Recommended' : ''}
                price={
                  isUSD ? pricingItem.price.dollar : pricingItem.price.naira
                }
                duration={pricingItem.duration}
                link={pricingItem.link}
                benefits={pricingItem.benefits}
                savings={
                  isUSD ? pricingItem.savings.dollar : pricingItem.savings.naira
                }
              />
            ))}

          <Link
            to="/pricing"
            variant="secondary"
            fontSize="md"
            textDecoration="underline"
          >
            See Other Learning Options
          </Link>
        </Show>
      </Flex>
      <ScholarshipAvailable />
    </>
  )
}
