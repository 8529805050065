import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { ApplicationForm, Metadata } from '../components'
import WhiteLogo from '../assets/white-logo.png'
import BlackLogo from '../assets/black-logo.png'

export const ApplicationPage: React.FC = () => {
  return (
    <>
      <Metadata
        title="Register to Start Learning"
        description="Register to start learning with Dot Campus."
      />
      <Flex
        marginX="auto"
        width={{ base: '100%' }}
        data-testid="application-page"
      >
        <Box
          display={{ base: 'none', md: 'block' }}
          width={{ base: '100%', md: '50%' }}
          bg="primary.900"
        >
          <Box textAlign="center" mt="24%">
            <Image
              src={WhiteLogo}
              mb="3"
              alt="Dot Campus Logo White"
              width="387px"
              marginX="auto"
            />
            <Text
              fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }}
              color="gray.100"
              width="65%"
              marginX="auto"
              my="10"
            >
              We are COMMITTED to empowering individuals across the world,
              especially in Africa by providing ACCESSIBLE and EFFECTIVE
              learning opportunities, guidance, mentorship, and resources, to
              enhance their prospects for personal and professional GROWTH.
            </Text>
            <Text
              fontSize="sm"
              fontWeight="semibold"
              color="gray.200"
              textAlign="center"
              padding="5"
            >
              © {new Date().getFullYear()} Dot Campus Africa LLC. All rights
              reserved
            </Text>
          </Box>
        </Box>
        <Box width={{ base: '100%', md: '50%' }}>
          <Image
            display={{ base: 'block', md: 'none' }}
            src={BlackLogo}
            mb="3"
            alt="Dot Campus Logo Black"
            width="387px"
            marginX="auto"
          />

          <ApplicationForm />
        </Box>
      </Flex>
    </>
  )
}
