import frontend from '../assets/frontend.jpg'
import webDev from '../assets/webdev.webp'
import dataAnalysis from '../assets/data-analysis.webp'
import productDesign from '../assets/product-design.webp'
import { ProgrammeType } from '../types'
import { learningInterests } from './courses'

export const programmes: ProgrammeType[] = [
  {
    title: 'Product Design',
    description: `Learn the fundamentals of product design, including user research, prototyping, and UI/UX design. Master tools like Figma, and more! 🎨
    `,
    learningOutcomes: [
      'User Research 🕵️',
      'Wireframing and Prototyping 🛠️',
      'Design Thinking 🧠',
      'UI/UX Design 🎨',
      'Figma, etc. 🛠️',
      'And much more!',
    ],
    link: '/product/product-design',
    duration: '6 Months',
    image: productDesign,
    key: learningInterests.productDesign,
  },
  {
    title: 'Data Analysis',
    description: `Learn the fundamentals of data analysis, including data cleaning, data visualization, and statistical analysis. Master tools like Excel, Python, SQL, Git, and more! 📊
    `,
    learningOutcomes: [
      'Excel 📊',
      'Python 🐍',
      'SQL 🗄️',
      'Data Visualization 📊',
      'Statistical Analysis 📈',
      'And much more!',
    ],
    link: '/data/data-analysis',
    duration: '6 Months',
    image: dataAnalysis,
    key: learningInterests.dataAnalysis,
  },
  {
    title: 'Frontend Engineering',
    description: `Excel in the core principles of software engineering, delve into ReactJS, and beyond. Craft responsive and secure web interfaces, champion web security, optimize performance, and perfect backend communication.

    `,
    learningOutcomes: [
      'Software Engineering best practices and principles',
      'Advanced Data Structures and Algorithms 🔢',
      'React.js (Hooks, Context, Redux) ⚛️',
      'Next.js',
      'Web Security 🔒',
      'Software application testing 🧪🔍',
      'And much more!',
    ],
    link: '/engineering/frontend',
    duration: '6 Months',
    image: frontend,
    key: learningInterests.frontend,
  },
  {
    title: 'Backend Engineering',
    description: `
    Master JavaScript/Node.js, Python, Ruby, and frameworks like Django, Flask, Ruby on Rails, and NestJS/Express.
    Learn database design, API development, security, scalability, and more. 🚀
        `,
    learningOutcomes: [
      'Software Engineering best practices and principles',
      'Node.js (Express) or Python/Django or Ruby on Rails or C# (.NET)',
      'Database Design and Management 🗄️',
      'Web Security 🔒',
      'And much more!',
    ],
    link: '/engineering/backend',
    duration: '6 Months',
    image: webDev,
    key: learningInterests.backend,
  },
]
