import {
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react'
import React from 'react'

export const RecommendationFormContent = ({
  currentStep,
  setCurrentStep,
  handleSelection,
  theStep,
}: {
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  handleSelection: (step: number, value: string) => void
  theStep: {
    title: string
    options: string[]
    image: string
  }
}): React.ReactElement => {
  const templateColumns =
    theStep.options.length > 4 ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'
  return (
    <>
      <Heading as="h3" color="gray.900" fontWeight="500" pb="6">
        {theStep.title}
      </Heading>
      <HStack my="3">
        <Grid
          templateColumns={{
            base: 'unset',
            md: templateColumns,
          }}
          gap={3}
          width="70%"
        >
          {theStep.options.map(option => (
            <GridItem key={option}>
              <Button
                variant="outline"
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                onClick={() => handleSelection(currentStep, option)}
                fontSize={{ base: 'md' }}
              >
                {option}
              </Button>
            </GridItem>
          ))}
        </Grid>
        <Image
          alt={theStep.title}
          src={theStep.image}
          width="20%"
          maxHeight="200px"
          display={{ base: 'none', md: 'initial' }}
        />
      </HStack>
      <HStack>
        <Text
          color="primary.500"
          fontSize={{ base: 'lg', md: 'xl' }}
          fontWeight="bold"
          mr="5"
        >
          {currentStep + 1}/3
        </Text>
        {currentStep > 0 && (
          <Button
            variant="link"
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            onClick={() => setCurrentStep(currentStep - 1)}
            fontSize="lg"
          >
            Back
          </Button>
        )}
      </HStack>
    </>
  )
}
