import { Text } from '@chakra-ui/react'
import React from 'react'

export const ScholarshipAvailable = (): React.ReactElement => {
  return (
    <Text color="gray.800" mt="8">
      {' '}
      NB: Partial scholarships are available on needs basis.{' '}
    </Text>
  )
}
