import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react'
import React from 'react'
import { APPLICATION_MAX_WIDTH, Webinar } from '../../constants'
import checklistIcon from '../../assets/checklist-icon.png'

export const WebinarDetails = ({
  currentWebinar,
  isPastDate,
}: {
  currentWebinar: Webinar
  isPastDate: boolean
}): React.ReactElement => {
  return (
    <Box
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      mt={4}
      data-testid="webinar-details"
      width={APPLICATION_MAX_WIDTH}
      marginX="auto"
    >
      {isPastDate ? (
        <Box padding={{ base: '5', md: '10' }}>
          <Heading as="h4">
            Register to get notified when next we have a webinar
          </Heading>
        </Box>
      ) : (
        <Box
          width={{ base: '100%', md: '40%' }}
          padding={{ base: '5', md: '10' }}
        >
          <Box>
            <Heading as="h4">Details</Heading>
            <Text fontSize={{ base: 'sm', md: 'md' }}>
              {currentWebinar.description}
            </Text>
          </Box>

          <Box my={5}>
            <Heading as="h4">What you’ll be learning</Heading>
            <Box my={4} bgColor={'#F0F6FF'} p={5}>
              {currentWebinar.learningPoints.map(point => (
                <Flex key={point}>
                  <Image
                    src={checklistIcon}
                    alt="Checklist Icon"
                    height={{ base: '20px', md: '20px' }}
                    width={{ base: '20px', md: '20px' }}
                  />
                  <Text
                    color="gray.900"
                    mb="4"
                    ml="4"
                    textAlign={'left'}
                    fontSize={{ base: 'sm', lg: 'md' }}
                    fontFamily={'heading'}
                  >
                    {point}
                  </Text>
                </Flex>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      <Box
        width={{ base: '100%', md: '60%' }}
        position="relative"
        top={{ base: 'unset', md: '-120px' }}
        minHeight="750px"
      >
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScIM_704H1LN3KW6KUmFy3n-UOnZqn-byYRMdcirKdOpLXsNg/viewform?embedded=true"
          width="100%"
          height="100%"
          data-testid="iframe-webinar"
        >
          Loading…
        </iframe>
        {/* <iframe
          id="iframewin"
          data-testid="iframe-webinar"
          width="100%"
          height="100%"
          src="https://zcm1-zcmp.maillist-manage.eu/ua/Optin?od=12ba7eb9d355&zx=14accd5f62&tD=1239f63c29514d71&sD=1239f63c29557bcb"
        ></iframe> */}
      </Box>
    </Box>
  )
}
