import { Card, CardBody, CardFooter, Heading, Image } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { Link as ReactRouterDomLink } from 'react-router-dom'
import { Link } from '../common'

export function DeliveryModeCard({
  children,
  tag,
  title,
}: {
  children: ReactElement
  tag: string
  title: string
}): ReactElement {
  return (
    <Card
      p="4"
      boxShadow="md"
      bgColor="gray.100"
      width={{ base: '100%', md: '48%' }}
    >
      <Image src={tag} width="30px" pos="absolute" top="0" left="0" />
      <CardBody px={10} py={5}>
        <Heading as="h4" mb="1">
          {title}
        </Heading>
        <Heading
          as="h6"
          mb="5"
          bgGradient="linear(to-r,  #FFC719 0%,  #FF2200 51%, #FF2200 100%)"
          bgClip="text"
        >
          online learning
        </Heading>
        {children}
      </CardBody>
      <CardFooter mt="auto" mb="5">
        <Link
          variant="buttonSecondary"
          as={ReactRouterDomLink}
          to="/register"
          fontSize={{ base: 'sm', lg: 'lg' }}
          width="fit-content"
          mx={{ base: 'auto' }}
        >
          Register Now
        </Link>
      </CardFooter>
    </Card>
  )
}
