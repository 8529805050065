import { ComponentStyleConfig } from '@chakra-ui/react'

export const Table: ComponentStyleConfig = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    primary: {
      th: {
        color: 'primary.900',
        bg: 'gray.200',
        border: '1px solid',
        borderColor: 'gray.300',
        padding: { base: '2', md: '4' },
      },
      td: {
        color: 'gray.800',
        border: '1px solid',
        borderColor: 'gray.300',
        padding: { base: '2', md: '4' },
        textAlign: 'center',
      },
    },
  },
}
