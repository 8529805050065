import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Heading,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import {
  firstName,
  lastName,
  email,
  phoneNumber,
  referralCode,
  learningInterest,
  learningPreference,
  referredBy,
  countryOfResidence,
  paymentPlan,
  preferredPaymentCurrency,
} from '../../validators'
import { Input, Select } from '../Input'
import { countries, HAS_SUBMITTED_APPLICATION_FORM } from '../../constants'
import { FormSuccess } from './FormSuccess'
import { FormClosed } from './FormClosed'
import { getSessionStorageBoolean } from '../../utils'
import { handleApplicationSubmission } from './handleApplicationSubmission'
import { getPaymentDetails } from '../../utils/getPaymentDetails'
import { getLearningPath } from '../../utils/getLearningPath'
import { RegistrationPaymentInfo } from '../../types'
import { learningInterestChoices } from '../../constants/options'

const validationSchema = yup.object().shape({
  firstName,
  lastName,
  email,
  phoneNumber,
  referralCode,
  learningInterest,
  learningPreference,
  referredBy,
  countryOfResidence,
  paymentPlan,
  preferredPaymentCurrency,
})

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  countryOfResidence: '',
  phoneNumber: '',
  learningPreference: '',
  employmentStatus: '',
  learningInterest: '',
  preferredPaymentCurrency: '',
  paymentPlan: '',
  referredBy: '',
  referralCode: '',
  payNow: false,
}

export const ApplicationForm: React.FC = () => {
  const [isLoading, setLoading] = useState(false)
  const [onSuccess, setOnSuccess] = useState(false)
  const [paymentDetails, setPaymentDetails] =
    useState<RegistrationPaymentInfo | null>(null)

  const toast = useToast()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      const learningPath = getLearningPath(values.learningInterest)
      setLoading(true)
      handleApplicationSubmission(
        setLoading,
        setOnSuccess,
        toast,
        { ...values, learningPath },
        paymentDetails,
      )
    },
  })

  const { REACT_APP_FORM_CLOSED } = process.env

  useEffect(() => {
    ;(async (): Promise<void> => {
      if (
        formik.values.firstName &&
        formik.values.lastName &&
        formik.values.email &&
        formik.values.phoneNumber &&
        formik.values.learningInterest &&
        formik.values.learningPreference &&
        formik.values.paymentPlan &&
        formik.values.preferredPaymentCurrency
      ) {
        // get payment details
        const data = await getPaymentDetails({
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
          learningInterest: formik.values.learningInterest,
          learningPreference: formik.values.learningPreference,
          paymentPlan: formik.values.paymentPlan,
          paymentCurrency: formik.values.preferredPaymentCurrency,
          referralCode: formik.values.referralCode,
        })
        // set payment details in sessionStorage
        sessionStorage.setItem('paymentDetails', JSON.stringify(data))
        // set state
        setPaymentDetails(data)
      }
    })()
  }, [formik.values])

  // extract the form values from page URL params
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const paramLearningPreference = urlParams.get('learningPreference')
    const paramPaymentPlan = urlParams.get('paymentPlan')
    const paramReferralCode = urlParams.get('rf')
    const paramLearningInterest = urlParams.get('course')

    if (paramLearningPreference) {
      formik.setFieldValue('learningPreference', paramLearningPreference)
    }
    if (paramPaymentPlan) {
      formik.setFieldValue('paymentPlan', paramPaymentPlan)
    }
    if (paramReferralCode) {
      formik.setFieldValue('referralCode', paramReferralCode)
    }
    if (paramLearningInterest) {
      formik.setFieldValue('learningInterest', paramLearningInterest)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (REACT_APP_FORM_CLOSED && REACT_APP_FORM_CLOSED === 'true') {
    return <FormClosed />
  }

  if (onSuccess || getSessionStorageBoolean(HAS_SUBMITTED_APPLICATION_FORM)) {
    return <FormSuccess />
  }

  const { values, handleChange, handleBlur, handleSubmit, touched, errors } =
    formik

  const buttonText =
    paymentDetails && formik.values.payNow ? 'Proceed to Pay Now' : 'Submit'

  return (
    <Box mt="10">
      <Box
        maxWidth="768px"
        padding="10"
        marginX="auto"
        height="100%"
        overflowY="scroll"
      >
        <Heading as="h1" fontSize="3xl">
          Registration Form
        </Heading>
        <Text fontSize="md" color="gray.500" mb="7">
          Please fill in the form below to register for the program of your
          choice.
        </Text>
        <form onSubmit={handleSubmit}>
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              lg: 'repeat(2, 1fr)',
            }}
            gap={6}
          >
            <Input
              error={touched.firstName && errors.firstName}
              isRequired
              label="First Name"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.firstName}
            />
            <Input
              error={touched.lastName && errors.lastName}
              isRequired
              label="Last Name"
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.lastName}
            />
            <Input
              error={touched.email && errors.email}
              isRequired
              label="Email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.email}
            />
            <Input
              error={touched.phoneNumber && errors.phoneNumber}
              isRequired
              label="Phone Number"
              name="phoneNumber"
              onBlur={handleBlur}
              onChange={handleChange}
              type="phone"
              value={values.phoneNumber}
            />
            <Select
              error={touched.countryOfResidence && errors.countryOfResidence}
              isRequired
              name="countryOfResidence"
              label="Country of Residence"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.countryOfResidence}
              choices={countries.map(country => ({
                label: country.name,
                value: country.name,
              }))}
            />
            <Select
              error={touched.employmentStatus && errors.employmentStatus}
              isRequired
              label="Employment Status"
              name="employmentStatus"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.employmentStatus}
              choices={[
                { label: '', value: '' },
                { label: 'Student', value: 'student' },
                { label: 'Employed', value: 'employed' },
                { label: 'Unemployed', value: 'unemployed' },
              ]}
            />
            <Select
              error={touched.learningInterest && errors.learningInterest}
              isRequired
              name="learningInterest"
              label="Programme of Interest"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.learningInterest}
              choices={learningInterestChoices}
            />
            <Select
              error={touched.learningPreference && errors.learningPreference}
              isRequired
              name="learningPreference"
              label="Learning Preference"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.learningPreference}
              choices={[
                { label: '', value: '' },
                { label: 'Self-Paced Learning', value: 'guidedLearning' },
                {
                  label: 'Mentorship',
                  value: 'mentorship',
                },
              ]}
            />
            <Select
              error={touched.paymentPlan && errors.paymentPlan}
              isRequired
              name="paymentPlan"
              label="Payment Plan"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.paymentPlan}
              choices={[
                { label: '', value: '' },
                { label: 'Upfront', value: 'upfront' },
                { label: 'Quarterly', value: 'quarterly' },
                { label: 'Monthly', value: 'monthly' },
              ]}
            />
            <Select
              error={
                touched.preferredPaymentCurrency &&
                errors.preferredPaymentCurrency
              }
              name="preferredPaymentCurrency"
              label="Preferred Payment Currency"
              isRequired
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.preferredPaymentCurrency}
              choices={[
                { label: '', value: '' },
                { label: 'NGN', value: 'ngn' },
                { label: 'USD', value: 'usd' },
              ]}
            />
            <Select
              error={touched.referredBy && errors.referredBy}
              isRequired
              label="How did you learn about us?"
              name="referredBy"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.referredBy}
              choices={[
                { label: '', value: '' },
                { label: 'WhatsApp', value: 'whatsApp' },
                { label: 'Instagram', value: 'instagram' },
                { label: 'YouTube', value: 'youTube' },
                { label: 'Google', value: 'google' },
                { label: 'X (Twitter)', value: 'twitter' },
                { label: 'Facebook', value: 'facebook' },
                { label: 'LinkedIn', value: 'linkedIn' },
                { label: 'Friend', value: 'friend' },
                { label: 'Other', value: 'other' },
              ]}
            />
            <Input
              error={touched.referralCode && errors.referralCode}
              label="Referral Code"
              name="referralCode"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.referralCode}
            />
          </Grid>
          <Checkbox
            name="payNow"
            onBlur={handleBlur}
            onChange={handleChange}
            checked={values.payNow}
            isChecked={values.payNow}
          >
            Pay Now
          </Checkbox>
          {paymentDetails && (
            <Text color="gray.600" my="3" mt="5">
              {paymentDetails.paymentNote}{' '}
            </Text>
          )}
          <Text fontSize="md" color="gray.600" my="3">
            By clicking &ldquo;{buttonText}&rdquo;, you agree to our{' '}
            <a
              href="https://dotcampus.notion.site/Terms-of-Service-for-DCLC-82648a6178cf4a93ba72404712835dce?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </Text>
          <Button
            variant="secondary"
            type="submit"
            my="4"
            width="full"
            isLoading={isLoading}
            disabled={isLoading || !paymentDetails}
          >
            {buttonText}
          </Button>
        </form>
      </Box>
    </Box>
  )
}
