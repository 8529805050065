import React, { useEffect } from 'react'
import { Flex, FlexProps } from '@chakra-ui/react'

import { APPLICATION_MAX_WIDTH } from '../constants'
import { LayoutProps } from './types'
import { useLocation } from 'react-router-dom'

export interface BaseLayoutProps extends LayoutProps {
  fullWidth?: boolean
}

/**
 * The "base" page layout.
 * All other page layouts derive from this one.
 * Sets the page max-with and min-height as well as "global" page padding.
 */
export const BaseLayout: React.FC<BaseLayoutProps & FlexProps> = ({
  children,
  fullWidth,
  'data-testid': tid,
  ...props
}) => {
  const { pathname, hash } = useLocation()

  useEffect(() => {
    // navigate to blog if pathname looks like a blog post
    if (pathname.match(/^\/\d{4}\/\d{2}\/\d{2}\/.*\.html$/)) {
      window.location.href = `https://blog.dotcampus.co${pathname}`
      return
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1))
      targetElement?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [pathname, hash])

  return (
    <Flex
      align="stretch"
      data-testid={tid}
      margin="auto"
      maxWidth={fullWidth ? 'none' : APPLICATION_MAX_WIDTH}
      minHeight="100vh"
      px={fullWidth ? '0' : { base: 5, md: 10 }}
      wrap="wrap"
      bg="gray.200"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Flex>
  )
}
