import React from 'react'
import { RouterProvider } from 'react-router-dom'

import router from './routes'
import { Providers } from './providers'
import { ColorModeScript } from '@chakra-ui/react'
import theme from './theme'

const App: React.FC = () => {
  return (
    <Providers>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <RouterProvider router={router} />
    </Providers>
  )
}

export default App
