import { Image } from '@chakra-ui/react'
import React from 'react'
import { Link } from './Link'

export const SocialIconLink = ({
  iconSrc,
  url,
  name,
  width = '24px',
}: {
  iconSrc: string
  url: string
  name: string
  width?: string
}): React.ReactElement => {
  return (
    <Link to={url} isExternal variant="muted" fontSize="md" mr="2">
      <Image src={iconSrc} alt={name} width={width} />
    </Link>
  )
}
