import { ComponentStyleConfig } from '@chakra-ui/react'

export const Text: ComponentStyleConfig = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    primary: {
      fontSize: 'md',
      color: 'gray.700',
    },
    disclosure: {
      color: 'gray.900',
      fontSize: 'sm',
    },
    error: {
      color: 'red.500',
      fontSize: 'sm',
    },
    link: {
      cursor: 'pointer',
      color: 'blue.500',
      textDecoration: 'underline',
    },
  },
}

export const Heading: ComponentStyleConfig = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    primary: {
      color: 'primary.500',
      fontWeight: '600',
      fontFamily: 'heading',
    },
    secondary: {
      color: 'orange.500',
      fontWeight: '600',
      fontFamily: 'heading',
    },
    muted: {
      color: 'gray.700',
      fontWeight: '500',
      fontFamily: 'heading',
    },
  },
}
