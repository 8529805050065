import { FLUTTERWAVE_PUBLIC_KEY } from '../constants'

export function payWithFlutterwave({
  email,
  amount,
  paymentReference,
  firstName,
  lastName,
  currency = 'USD',
  phoneNumber,
  planCode,
  handleClose,
  handleCallback,
}: {
  email: string
  amount: number
  firstName: string
  lastName: string
  currency?: string
  phoneNumber: string
  paymentReference: string
  planCode?: number
  handleClose?: () => void
  handleCallback?: (response: {
    tx_ref: string
    transaction_id: number
  }) => void
}): void {
  /* istanbul ignore next */
  if (!window.FlutterwaveCheckout) return
  const modal = window.FlutterwaveCheckout({
    public_key: FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: paymentReference,
    amount,
    currency,
    // payment_options: 'card, banktransfer, ussd',
    // redirect_url: 'https://dotcampus.co/register',
    payment_plan: planCode,
    customer: {
      email,
      phone_number: phoneNumber,
      name: `${firstName} ${lastName}`,
    },
    customizations: {
      title: 'DCLC Programme Registration Fee',
      description: 'Payment for the DCLC Programme Registration Fee',
    },

    callback: /* istanbul ignore next */ function (response) {
      handleCallback && handleCallback(response)
    },

    onclose: /* istanbul ignore next */ function () {
      handleClose && handleClose()
      modal.close()
    },
  })
}
