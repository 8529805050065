import { Box, Heading } from '@chakra-ui/react'
import React, { useEffect } from 'react'

import onlineLearningImage from '../../assets/arts/undraw_online_learning.svg'
import goalsImage from '../../assets/arts/undraw_goals.svg'
import progressImage from '../../assets/arts/undraw_progress.svg'
import { RecommendationFormContent } from './RecommendationFormContent'
import { RecommendationResult } from './RecommendationResult'
import { handleRecommendationRequest } from './handleRecommendationRequest'
import { RecommendationResultType } from '../../types'

const productDesign = 'Product Design'
const steps = [
  {
    title: 'What do you want to learn?',
    options: [
      'Data Analysis',
      'Web Development',
      'Frontend Engineering',
      'Backend Engineering',
      productDesign,
      'Cyber Security',
      'Software QA Engineering',
      'Cloud Computing/DevOps',
      'Blockchain',
      'Not sure',
    ],
    image: onlineLearningImage,
  },
  {
    title: 'What do you want to achieve?',
    options: [
      'Career Change',
      'Learn for fun',
      'Skill Enhancement',
      'Not sure',
      'Build a project',
    ],
    image: goalsImage,
  },
  {
    title: 'How much coding experience do you have?',
    options: ['None', 'Beginner', 'Intermediate', 'Advanced'],
    image: progressImage,
  },
]

export const RecommendationForm = (): React.ReactElement => {
  const [currentStep, setCurrentStep] = React.useState<number>(0)
  const [userSelection, setUserSelection] = React.useState<string[]>([])
  const [recommendation, setRecommendation] =
    React.useState<RecommendationResultType | null>(null)

  const handleSelection = React.useCallback(
    (step: number, value: string): void => {
      const updatedSelection = [...userSelection]
      if (step === 1 && updatedSelection[0] === productDesign) {
        updatedSelection[step] = value
        updatedSelection[2] = 'None'
        setCurrentStep(currentStep + 2)
      } else {
        updatedSelection[step] = value
        setCurrentStep(currentStep + 1)
      }
      setUserSelection(updatedSelection)
    },
    [userSelection, currentStep],
  )

  const theStep = steps[currentStep]

  useEffect(() => {
    ;(async function (): Promise<void> {
      if (userSelection.length === 3) {
        // Send the userSelection to the backend for processing
        const result = await handleRecommendationRequest({
          learningInterest: userSelection[0],
          purpose: userSelection[1],
          codingExperienceLevel: userSelection[2],
        })
        setRecommendation(result)
      }
    })()
  }, [userSelection])

  if (recommendation) {
    return (
      <RecommendationResult
        learningInterest={recommendation.learningInterest}
        deliveryMode={recommendation.deliveryMode}
        explanationForDeliveryMode={recommendation.explanationForDeliveryMode}
        explanationForLearningInterest={
          recommendation.explanationForLearningInterest
        }
      />
    )
  }

  return (
    <Box
      border="3px solid #FF2200 !important"
      borderRadius="10"
      padding="10"
      bgColor="#eda69b24"
    >
      {theStep && (
        <RecommendationFormContent
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleSelection={handleSelection}
          theStep={theStep}
        />
      )}
      {currentStep > 2 && <Heading as="h3">Please hold...</Heading>}
    </Box>
  )
}
