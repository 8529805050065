import { Box, Heading, Text, Image } from '@chakra-ui/react'
import React from 'react'
import { APPLICATION_MAX_WIDTH, Webinar } from '../../constants'
import speakerName from '../../assets/speakerName.png'

export const WebinarSpeaker = ({
  currentWebinar,
  isPastDate,
}: {
  currentWebinar: Webinar
  isPastDate: boolean
}): React.ReactElement => {
  if (currentWebinar.panelists) return <></>
  if (isPastDate) return <></>
  return (
    <>
      <Box
        data-testid="webinar-speaker"
        zIndex={1}
        maxWidth={APPLICATION_MAX_WIDTH}
        mx="auto"
        px={{ base: '5', md: '10' }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          my={6}
          mt={{ base: '0', md: '-120px' }}
        >
          <Box mr={{ base: 'unset', md: '20' }}>
            <Image
              src={currentWebinar.speakerImage}
              alt={currentWebinar.speaker}
              width="320px"
            />
            <Box mt="-70" width="320px">
              <Image src={speakerName} width="320px" />
              <Text
                color={'gray.100'}
                fontFamily={'heading'}
                fontWeight={'bold'}
                fontSize="xl"
                mt="-50"
                ml="70"
                transform="rotate(-4deg)"
              >
                {currentWebinar.speaker}
              </Text>
            </Box>
          </Box>
          <Box
            my={{ base: 'unset', md: 'auto' }}
            mt={{ base: '20', md: '0' }}
            width={{ base: 'unset', lg: '70%' }}
          >
            <Heading as="h4">Meet The Speaker</Heading>
            <Text
              fontSize={{ base: 'md', md: 'lg' }}
              fontFamily={'heading'}
              data-testid="speaker-bio"
            >
              {currentWebinar.aboutSpeaker}
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  )
}
