import { Box } from '@chakra-ui/react'
import React from 'react'
import { SectionTitle } from '../common'
import { APPLICATION_MAX_WIDTH } from '../../constants'
import { RecommendationForm } from './RecommendationForm'

export const RecommendationsSection = (): React.ReactElement => {
  return (
    <Box
      padding="8"
      data-testid="home-recommendations-section"
      width="100%"
      maxWidth={APPLICATION_MAX_WIDTH}
      margin="auto"
      as="section"
    >
      <SectionTitle
        titlePart1="Find your ideal course"
        titlePart2=""
        description="Answer 3 quick questions to receive personalized, AI-driven recommendations tailored to your goals, interests, and experience."
      />
      <RecommendationForm />
    </Box>
  )
}
