import { PAYSTACK_PUBLIC_KEY } from '../constants'

export function payWithPaystack({
  email,
  amount,
  paymentReference,
  planCode,
  handleClose,
  handleCallback,
}: {
  email: string
  amount: number
  paymentReference: string
  planCode?: string
  handleClose?: () => void
  handleCallback?: (response: { reference: string }) => void
}): void {
  if (!window.PaystackPop) return
  const handler = window.PaystackPop.setup({
    key: PAYSTACK_PUBLIC_KEY,
    email,
    amount: amount * 100,
    ref: paymentReference,
    plan: planCode,

    onClose: /* istanbul ignore next */ function () {
      handleClose && handleClose()
    },

    callback: /* istanbul ignore next */ function (response) {
      handleCallback && handleCallback(response)
    },
  })

  handler.openIframe()
}
