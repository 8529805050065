import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react'
import { Link } from '../common'
import { getCourse } from '../../utils/getCourse'
import { getCourseRegistrationLink } from '../../utils/getLearningPath'
import { RecommendationResultType } from '../../types'

export const RecommendationResult = (
  result: RecommendationResultType,
): React.ReactElement => {
  const course = getCourse(result.learningInterest)
  return (
    <Box
      border="3px solid #FF2200 !important"
      borderRadius="10"
      padding="10"
      bgColor="#eda69b24"
    >
      <Box mb="10">
        <Text fontSize="xl" color="gray.900" fontWeight="600" pb="4">
          Learning Interest:{' '}
          <Box
            as="span"
            color="orange.700"
            fontSize="inherit"
            fontWeight="inherit"
          >
            {result.learningInterest}
          </Box>
        </Text>
        <Text fontSize="lg">{result.explanationForLearningInterest}</Text>
      </Box>
      <Box mb="10">
        <Text fontSize="xl" color="gray.900" fontWeight="600" pb="4">
          Delivery Mode:{' '}
          <Box
            as="span"
            color="orange.700"
            fontSize="inherit"
            fontWeight="inherit"
          >
            {result.deliveryMode}
          </Box>
        </Text>
        <Text fontSize="lg">{result.explanationForDeliveryMode}</Text>
      </Box>
      {course && (
        <>
          <Box mb="10">
            <Text fontSize="xl" color="orange.700" fontWeight="600" pb="4">
              Course Outline
            </Text>
            <UnorderedList role="list" pl={3} fontSize="lg">
              {course.learningOutcomes.map(learningOutcome => (
                <ListItem
                  color="gray.800"
                  fontSize="lg"
                  mb={3}
                  key={learningOutcome}
                >
                  {learningOutcome}
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
          <Link
            variant="buttonSecondary"
            to={getCourseRegistrationLink(course.key)}
          >
            Register Now
          </Link>
        </>
      )}
    </Box>
  )
}
