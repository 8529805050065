import { Box, BoxProps } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

import BackgroundImage from '../../assets/hero-background.svg'

export const FullBackgroundImage: React.FC<PropsWithChildren & BoxProps> = ({
  children,
  ...props
}) => {
  return (
    <Box
      backgroundImage={BackgroundImage}
      backgroundSize={{ base: 'contain', md: 'cover' }}
      backgroundRepeat="no-repeat"
      width="100%"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Box>
  )
}
