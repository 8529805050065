export const countries = [
  { name: '', shortCode: '', phoneCode: '' },
  { name: 'Nigeria', shortCode: 'NG', phoneCode: '+234' },
  { name: 'Afghanistan', shortCode: 'AF', phoneCode: '+93' },
  { name: 'Albania', shortCode: 'AL', phoneCode: '+355' },
  { name: 'Algeria', shortCode: 'DZ', phoneCode: '+213' },
  { name: 'American Samoa', shortCode: 'AS', phoneCode: '+1 684' },
  { name: 'Andorra', shortCode: 'AD', phoneCode: '+376' },
  { name: 'Angola', shortCode: 'AO', phoneCode: '+244' },
  { name: 'Anguilla', shortCode: 'AI', phoneCode: '+1 264' },
  { name: 'Antigua and Barbuda', shortCode: 'AG', phoneCode: '+1268' },
  { name: 'Argentina', shortCode: 'AR', phoneCode: '+54' },
  { name: 'Armenia', shortCode: 'AM', phoneCode: '+374' },
  { name: 'Aruba', shortCode: 'AW', phoneCode: '+297' },
  { name: 'Australia', shortCode: 'AU', phoneCode: '+61' },
  { name: 'Austria', shortCode: 'AT', phoneCode: '+43' },
  { name: 'Azerbaijan', shortCode: 'AZ', phoneCode: '+994' },
  { name: 'Bahamas', shortCode: 'BS', phoneCode: '+1 242' },
  { name: 'Bahrain', shortCode: 'BH', phoneCode: '+973' },
  { name: 'Bangladesh', shortCode: 'BD', phoneCode: '+880' },
  { name: 'Barbados', shortCode: 'BB', phoneCode: '+1 246' },
  { name: 'Belarus', shortCode: 'BY', phoneCode: '+375' },
  { name: 'Belgium', shortCode: 'BE', phoneCode: '+32' },
  { name: 'Belize', shortCode: 'BZ', phoneCode: '+501' },
  { name: 'Benin', shortCode: 'BJ', phoneCode: '+229' },
  { name: 'Bermuda', shortCode: 'BM', phoneCode: '+1 441' },
  { name: 'Bhutan', shortCode: 'BT', phoneCode: '+975' },
  { name: 'Bolivia', shortCode: 'BO', phoneCode: '+591' },
  { name: 'Bosnia and Herzegovina', shortCode: 'BA', phoneCode: '+387' },
  { name: 'Botswana', shortCode: 'BW', phoneCode: '+267' },
  { name: 'Brazil', shortCode: 'BR', phoneCode: '+55' },
  {
    name: 'British Indian Ocean Territory',
    shortCode: 'IO',
    phoneCode: '+246',
  },
  { name: 'British Virgin Islands', shortCode: 'VG', phoneCode: '+1 284' },
  { name: 'Brunei', shortCode: 'BN', phoneCode: '+673' },
  { name: 'Bulgaria', shortCode: 'BG', phoneCode: '+359' },
  { name: 'Burkina Faso', shortCode: 'BF', phoneCode: '+226' },
  { name: 'Burundi', shortCode: 'BI', phoneCode: '+257' },
  { name: 'Cambodia', shortCode: 'KH', phoneCode: '+855' },
  { name: 'Cameroon', shortCode: 'CM', phoneCode: '+237' },
  { name: 'Canada', shortCode: 'CA', phoneCode: '+1' },
  { name: 'Cape Verde', shortCode: 'CV', phoneCode: '+238' },
  { name: 'Caribbean Netherlands', shortCode: 'BQ', phoneCode: '+599' },
  { name: 'Cayman Islands', shortCode: 'KY', phoneCode: '+1 345' },
  { name: 'Central African Republic', shortCode: 'CF', phoneCode: '+236' },
  { name: 'Chad', shortCode: 'TD', phoneCode: '+235' },
  { name: 'Chile', shortCode: 'CL', phoneCode: '+56' },
  { name: 'China', shortCode: 'CN', phoneCode: '+86' },
  { name: 'Christmas Island', shortCode: 'CX', phoneCode: '+61' },
  { name: 'Cocos (Keeling) Islands', shortCode: 'CC', phoneCode: '+61' },
  { name: 'Colombia', shortCode: 'CO', phoneCode: '+57' },
  { name: 'Comoros', shortCode: 'KM', phoneCode: '+269' },
  { name: 'Congo (DRC)', shortCode: 'CD', phoneCode: '+243' },
  { name: 'Congo (Republic)', shortCode: 'CG', phoneCode: '+242' },
  { name: 'Cook Islands', shortCode: 'CK', phoneCode: '+682' },
  { name: 'Costa Rica', shortCode: 'CR', phoneCode: '+506' },
  { name: 'Côte d’Ivoire', shortCode: 'CI', phoneCode: '+225' },
  { name: 'Croatia', shortCode: 'HR', phoneCode: '+385' },
  { name: 'Cuba', shortCode: 'CU', phoneCode: '+53' },
  { name: 'Curaçao', shortCode: 'CW', phoneCode: '+599' },
  { name: 'Cyprus', shortCode: 'CY', phoneCode: '+357' },
  { name: 'Czech Republic', shortCode: 'CZ', phoneCode: '+420' },
  { name: 'Denmark', shortCode: 'DK', phoneCode: '+45' },
  { name: 'Djibouti', shortCode: 'DJ', phoneCode: '+253' },
  { name: 'Dominica', shortCode: 'DM', phoneCode: '+1 767' },
  { name: 'Dominican Republic', shortCode: 'DO', phoneCode: '+1 809' },
  { name: 'Ecuador', shortCode: 'EC', phoneCode: '+593' },
  { name: 'Egypt', shortCode: 'EG', phoneCode: '+20' },
  { name: 'El Salvador', shortCode: 'SV', phoneCode: '+503' },
  { name: 'Equatorial Guinea', shortCode: 'GQ', phoneCode: '+240' },
  { name: 'Eritrea', shortCode: 'ER', phoneCode: '+291' },
  { name: 'Estonia', shortCode: 'EE', phoneCode: '+372' },
  { name: 'Ethiopia', shortCode: 'ET', phoneCode: '+251' },
  { name: 'Falkland Islands', shortCode: 'FK', phoneCode: '+500' },
  { name: 'Faroe Islands', shortCode: 'FO', phoneCode: '+298' },
  { name: 'Fiji', shortCode: 'FJ', phoneCode: '+679' },
  { name: 'Finland', shortCode: 'FI', phoneCode: '+358' },
  { name: 'France', shortCode: 'FR', phoneCode: '+33' },
  { name: 'French Guiana', shortCode: 'GF', phoneCode: '+594' },
  { name: 'French Polynesia', shortCode: 'PF', phoneCode: '+689' },
  { name: 'Gabon', shortCode: 'GA', phoneCode: '+241' },
  { name: 'Gambia', shortCode: 'GM', phoneCode: '+220' },
  { name: 'Georgia', shortCode: 'GE', phoneCode: '+995' },
  { name: 'Germany', shortCode: 'DE', phoneCode: '+49' },
  { name: 'Ghana', shortCode: 'GH', phoneCode: '+233' },
  { name: 'Gibraltar', shortCode: 'GI', phoneCode: '+350' },
  { name: 'Greece', shortCode: 'GR', phoneCode: '+30' },
  { name: 'Greenland', shortCode: 'GL', phoneCode: '+299' },
  { name: 'Grenada', shortCode: 'GD', phoneCode: '+1 473' },
  { name: 'Guadeloupe', shortCode: 'GP', phoneCode: '+590' },
  { name: 'Guam', shortCode: 'GU', phoneCode: '+1 671' },
  { name: 'Guatemala', shortCode: 'GT', phoneCode: '+502' },
  { name: 'Guernsey', shortCode: 'GG', phoneCode: '+44' },
  { name: 'Guinea', shortCode: 'GN', phoneCode: '+224' },
  { name: 'Guinea-Bissau', shortCode: 'GW', phoneCode: '+245' },
  { name: 'Guyana', shortCode: 'GY', phoneCode: '+592' },
  { name: 'Haiti', shortCode: 'HT', phoneCode: '+509' },
  { name: 'Honduras', shortCode: 'HN', phoneCode: '+504' },
  { name: 'Hong Kong', shortCode: 'HK', phoneCode: '+852' },
  { name: 'Hungary', shortCode: 'HU', phoneCode: '+36' },
  { name: 'Iceland', shortCode: 'IS', phoneCode: '+354' },
  { name: 'India', shortCode: 'IN', phoneCode: '+91' },
  { name: 'Indonesia', shortCode: 'ID', phoneCode: '+62' },
  { name: 'Iran', shortCode: 'IR', phoneCode: '+98' },
  { name: 'Iraq', shortCode: 'IQ', phoneCode: '+964' },
  { name: 'Ireland', shortCode: 'IE', phoneCode: '+353' },
  { name: 'Isle of Man', shortCode: 'IM', phoneCode: '+44' },
  { name: 'Israel', shortCode: 'IL', phoneCode: '+972' },
  { name: 'Italy', shortCode: 'IT', phoneCode: '+39' },
  { name: 'Jamaica', shortCode: 'JM', phoneCode: '+1 876' },
  { name: 'Japan', shortCode: 'JP', phoneCode: '+81' },
  { name: 'Jordan', shortCode: 'JO', phoneCode: '+962' },
  { name: 'Kazakhstan', shortCode: 'KZ', phoneCode: '+7' },
  { name: 'Kenya', shortCode: 'KE', phoneCode: '+254' },
  { name: 'Kiribati', shortCode: 'KI', phoneCode: '+686' },
  { name: 'Kuwait', shortCode: 'KW', phoneCode: '+965' },
  { name: 'Kyrgyzstan', shortCode: 'KG', phoneCode: '+996' },
  { name: 'Laos', shortCode: 'LA', phoneCode: '+856' },
  { name: 'Latvia', shortCode: 'LV', phoneCode: '+371' },
  { name: 'Lebanon', shortCode: 'LB', phoneCode: '+961' },
  { name: 'Lesotho', shortCode: 'LS', phoneCode: '+266' },
  { name: 'Liberia', shortCode: 'LR', phoneCode: '+231' },
  { name: 'Libya', shortCode: 'LY', phoneCode: '+218' },
  { name: 'Liechtenstein', shortCode: 'LI', phoneCode: '+423' },
  { name: 'Lithuania', shortCode: 'LT', phoneCode: '+370' },
  { name: 'Luxembourg', shortCode: 'LU', phoneCode: '+352' },
  { name: 'Macau', shortCode: 'MO', phoneCode: '+853' },
  { name: 'Macedonia (FYROM)', shortCode: 'MK', phoneCode: '+389' },
  { name: 'Madagascar', shortCode: 'MG', phoneCode: '+261' },
  { name: 'Malawi', shortCode: 'MW', phoneCode: '+265' },
  { name: 'Malaysia', shortCode: 'MY', phoneCode: '+60' },
  { name: 'Maldives', shortCode: 'MV', phoneCode: '+960' },
  { name: 'Mali', shortCode: 'ML', phoneCode: '+223' },
  { name: 'Malta', shortCode: 'MT', phoneCode: '+356' },
  { name: 'Marshall Islands', shortCode: 'MH', phoneCode: '+692' },
  { name: 'Martinique', shortCode: 'MQ', phoneCode: '+596' },
  { name: 'Mauritania', shortCode: 'MR', phoneCode: '+222' },
  { name: 'Mauritius', shortCode: 'MU', phoneCode: '+230' },
  { name: 'Mayotte', shortCode: 'YT', phoneCode: '+262' },
  { name: 'Mexico', shortCode: 'MX', phoneCode: '+52' },
  { name: 'Micronesia', shortCode: 'FM', phoneCode: '+691' },
  { name: 'Moldova', shortCode: 'MD', phoneCode: '+373' },
  { name: 'Monaco', shortCode: 'MC', phoneCode: '+377' },
  { name: 'Mongolia', shortCode: 'MN', phoneCode: '+976' },
  { name: 'Montenegro', shortCode: 'ME', phoneCode: '+382' },
  { name: 'Montserrat', shortCode: 'MS', phoneCode: '+1 664' },
  { name: 'Morocco', shortCode: 'MA', phoneCode: '+212' },
  { name: 'Mozambique', shortCode: 'MZ', phoneCode: '+258' },
  { name: 'Myanmar (Burma)', shortCode: 'MM', phoneCode: '+95' },
  { name: 'Namibia', shortCode: 'NA', phoneCode: '+264' },
  { name: 'Nauru', shortCode: 'NR', phoneCode: '+674' },
  { name: 'Nepal', shortCode: 'NP', phoneCode: '+977' },
  { name: 'Netherlands', shortCode: 'NL', phoneCode: '+31' },
  { name: 'New Caledonia', shortCode: 'NC', phoneCode: '+687' },
  { name: 'New Zealand', shortCode: 'NZ', phoneCode: '+64' },
  { name: 'Nicaragua', shortCode: 'NI', phoneCode: '+505' },
  { name: 'Niger', shortCode: 'NE', phoneCode: '+227' },
  { name: 'Niue', shortCode: 'NU', phoneCode: '+683' },
  { name: 'Norfolk Island', shortCode: 'NF', phoneCode: '+672' },
  { name: 'North Korea', shortCode: 'KP', phoneCode: '+850' },
  { name: 'Northern Mariana Islands', shortCode: 'MP', phoneCode: '+1 670' },
  { name: 'Norway', shortCode: 'NO', phoneCode: '+47' },
  { name: 'Oman', shortCode: 'OM', phoneCode: '+968' },
  { name: 'Pakistan', shortCode: 'PK', phoneCode: '+92' },
  { name: 'Palau', shortCode: 'PW', phoneCode: '+680' },
  { name: 'Palestine', shortCode: 'PS', phoneCode: '+970' },
  { name: 'Panama', shortCode: 'PA', phoneCode: '+507' },
  { name: 'Papua New Guinea', shortCode: 'PG', phoneCode: '+675' },
  { name: 'Paraguay', shortCode: 'PY', phoneCode: '+595' },
  { name: 'Peru', shortCode: 'PE', phoneCode: '+51' },
  { name: 'Philippines', shortCode: 'PH', phoneCode: '+63' },
  { name: 'Poland', shortCode: 'PL', phoneCode: '+48' },
  { name: 'Portugal', shortCode: 'PT', phoneCode: '+351' },
  { name: 'Puerto Rico', shortCode: 'PR', phoneCode: '+1 787' },
  { name: 'Qatar', shortCode: 'QA', phoneCode: '+974' },
  { name: 'Réunion', shortCode: 'RE', phoneCode: '+262' },
  { name: 'Romania', shortCode: 'RO', phoneCode: '+40' },
  { name: 'Russia', shortCode: 'RU', phoneCode: '+7' },
  { name: 'Rwanda', shortCode: 'RW', phoneCode: '+250' },
  { name: 'Saint Barthélemy', shortCode: 'BL', phoneCode: '+590' },
  { name: 'Saint Helena', shortCode: 'SH', phoneCode: '+290' },
  { name: 'Saint Kitts and Nevis', shortCode: 'KN', phoneCode: '+1 869' },
  { name: 'Saint Lucia', shortCode: 'LC', phoneCode: '+1 758' },
  { name: 'Saint Martin', shortCode: 'MF', phoneCode: '+590' },
  { name: 'Saint Pierre and Miquelon', shortCode: 'PM', phoneCode: '+508' },
  {
    name: 'Saint Vincent and the Grenadines',
    shortCode: 'VC',
    phoneCode: '+1 784',
  },
  { name: 'Samoa', shortCode: 'WS', phoneCode: '+685' },
  { name: 'San Marino', shortCode: 'SM', phoneCode: '+378' },
  { name: 'São Tomé and Príncipe', shortCode: 'ST', phoneCode: '+239' },
  { name: 'Saudi Arabia', shortCode: 'SA', phoneCode: '+966' },
  { name: 'Senegal', shortCode: 'SN', phoneCode: '+221' },
  { name: 'Serbia', shortCode: 'RS', phoneCode: '+381' },
  { name: 'Seychelles', shortCode: 'SC', phoneCode: '+248' },
  { name: 'Sierra Leone', shortCode: 'SL', phoneCode: '+232' },
  { name: 'Singapore', shortCode: 'SG', phoneCode: '+65' },
  { name: 'Sint Maarten', shortCode: 'SX', phoneCode: '+1 721' },
  { name: 'Slovakia', shortCode: 'SK', phoneCode: '+421' },
  { name: 'Slovenia', shortCode: 'SI', phoneCode: '+386' },
  { name: 'Solomon Islands', shortCode: 'SB', phoneCode: '+677' },
  { name: 'Somalia', shortCode: 'SO', phoneCode: '+252' },
  { name: 'South Africa', shortCode: 'ZA', phoneCode: '+27' },
  { name: 'South Korea', shortCode: 'KR', phoneCode: '+82' },
  { name: 'South Sudan', shortCode: 'SS', phoneCode: '+211' },
  { name: 'Spain', shortCode: 'ES', phoneCode: '+34' },
  { name: 'Sri Lanka', shortCode: 'LK', phoneCode: '+94' },
  { name: 'Sudan', shortCode: 'SD', phoneCode: '+249' },
  { name: 'Suriname', shortCode: 'SR', phoneCode: '+597' },
  { name: 'Swaziland', shortCode: 'SZ', phoneCode: '+268' },
  { name: 'Sweden', shortCode: 'SE', phoneCode: '+46' },
  { name: 'Switzerland', shortCode: 'CH', phoneCode: '+41' },
  { name: 'Syria', shortCode: 'SY', phoneCode: '+963' },
  { name: 'Taiwan', shortCode: 'TW', phoneCode: '+886' },
  { name: 'Tajikistan', shortCode: 'TJ', phoneCode: '+992' },
  { name: 'Tanzania', shortCode: 'TZ', phoneCode: '+255' },
  { name: 'Thailand', shortCode: 'TH', phoneCode: '+66' },
  { name: 'Timor-Leste', shortCode: 'TL', phoneCode: '+670' },
  { name: 'Togo', shortCode: 'TG', phoneCode: '+228' },
  { name: 'Tokelau', shortCode: 'TK', phoneCode: '+690' },
  { name: 'Tonga', shortCode: 'TO', phoneCode: '+676' },
  { name: 'Trinidad and Tobago', shortCode: 'TT', phoneCode: '+1 868' },
  { name: 'Tunisia', shortCode: 'TN', phoneCode: '+216' },
  { name: 'Turkey', shortCode: 'TR', phoneCode: '+90' },
  { name: 'Turkmenistan', shortCode: 'TM', phoneCode: '+993' },
  { name: 'Turks and Caicos Islands', shortCode: 'TC', phoneCode: '+1 649' },
  { name: 'Tuvalu', shortCode: 'TV', phoneCode: '+688' },
  { name: 'U.S. Virgin Islands', shortCode: 'VI', phoneCode: '+1 340' },
  { name: 'Uganda', shortCode: 'UG', phoneCode: '+256' },
  { name: 'Ukraine', shortCode: 'UA', phoneCode: '+380' },
  { name: 'United Arab Emirates', shortCode: 'AE', phoneCode: '+971' },
  { name: 'United Kingdom', shortCode: 'GB', phoneCode: '+44' },
  { name: 'United States', shortCode: 'US', phoneCode: '+1' },
  { name: 'Uruguay', shortCode: 'UY', phoneCode: '+598' },
  { name: 'Uzbekistan', shortCode: 'UZ', phoneCode: '+998' },
  { name: 'Vanuatu', shortCode: 'VU', phoneCode: '+678' },
  { name: 'Vatican City', shortCode: 'VA', phoneCode: '+39' },
  { name: 'Venezuela', shortCode: 'VE', phoneCode: '+58' },
  { name: 'Vietnam', shortCode: 'VN', phoneCode: '+84' },
  { name: 'Wallis and Futuna', shortCode: 'WF', phoneCode: '+681' },
  { name: 'Yemen', shortCode: 'YE', phoneCode: '+967' },
  { name: 'Zambia', shortCode: 'ZM', phoneCode: '+260' },
  { name: 'Zimbabwe', shortCode: 'ZW', phoneCode: '+263' },
]
