import { Box, Heading, ListItem, UnorderedList } from '@chakra-ui/react'
import React from 'react'
import { APPLICATION_MAX_WIDTH } from '../../constants'

export const AdmissionRequirement: React.FC = () => {
  return (
    <Box bgColor="primary.500" width={'100%'}>
      <Box
        width={{ base: '90%' }}
        mx="auto"
        my={'50px'}
        color={'white'}
        bgGradient="linear-gradient(to right, #FFC719 0%, #eb662d 51%, #FF2200 100%)"
        borderRadius="lg"
        p={'2px'}
        maxWidth={APPLICATION_MAX_WIDTH}
        data-testid="admission-requirements"
      >
        <Box
          width={'100%'}
          p={6}
          bgColor="primary.500"
          maxWidth={APPLICATION_MAX_WIDTH}
          borderRadius={'8px'}
        >
          <Heading as="h4" mb="5" color="white">
            Admission Requirements
          </Heading>
          <UnorderedList>
            <ListItem mb={2}>
              Ability to write and speak English proficiently
            </ListItem>
            <ListItem mb={2}>
              A laptop or desktop computer with access to a stable internet
              connection
            </ListItem>
            <ListItem mb={2}>
              Must be 16 years or older at the time of registration
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>
    </Box>
  )
}
