import EricsImage from '../assets/webinar/Nnaike-Eric.webp'
import adetutuImage from '../assets/webinar/Adetutu Ajeyomi.png'
import maryImage from '../assets/webinar/Mary.png'
import ritaMaria from '../assets/webinar/Rita-Maria-O.jpg'
import tobiImage from '../assets/webinar/Tobi.png'
import BenedictImage from '../assets/webinar/Benedict.png'
// import PhilipImage from '../assets/webinar/Philip.png'
import GodwinImage from '../assets/webinar/Godwin.png'


export interface Panelist {
  name: string
  title: string
  company: string
  about: string
  image?: string
}

export interface Webinar {
  title: string
  description: string
  date: string
  time: string
  speaker: string
  aboutSpeaker: string
  learningPoints: string[]
  videoId?: string
  speakerImage?: string
  panelists?: Panelist[]
}

export const webinars: Webinar[] = [
  {
    title: 'Introduction to Backend Development - Codebase Architecture.',
    date: 'September, 14, 2024',
    time: '8:00PM (WAT)',
    speaker: 'Godwin Amadi',
    aboutSpeaker:
      'Godwin is a software developer with expertise in building and scaling enterprise solutions, with current focus in the Finance, Pension and Asset Management industry. Godwin also freelances as a technical writer with an appetite for providing accessible and simplified materials on varying subject topics. He is also an avid follower of technology and a developer by trade. Godwin loves taking up new challenges either by learning a new language or getting better understanding of a technology architecture.',
    speakerImage: GodwinImage,
    description: '',
    learningPoints: [
      'Be familiar with the principles of codebase architecture and how different architectural patterns impact the scalability, maintainability, and performance of applications.',
      'Learn about the most common and key architectural patterns used in backend development, such as MVC (Model-View-Controller), microservices, and monolithic architectures.',
      'Understand the approach to layered architecture and the benefits of separating concerns into different layers (e.g., presentation, business logic, data access) for better code maintainability and scalability.',
    ],
    videoId: '',
  },
  // {
  //   title:
  //     'Efficiency in Software Development. The Practice of Sprint Planning.',
  //   date: 'August, 24, 2024',
  //   time: '8:00PM (WAT)',
  //   speaker: 'Philip Osime',
  //   aboutSpeaker:
  //     "Philip Osime is a results-driven Senior Product Manager with over four years of experience in the fintech and e-commerce industries. He has a proven track record of translating market insights into innovative solutions, driving cross-functional teams, and maximizing revenue growth. With a strong technical background and a customer-centric approach, he has successfully led the development and launch of multiple products, including payment gateways, fintech applications and e-commerce platforms. He holds a Bachelor's Degree in Mechanical Engineering from The Federal University of Technology, Akure, and is certified by Product School and a few other institutes. In his current role at Global Accelerex Limited, Philip manages RexPay and RexRetail, focusing on secure and efficient payment solutions. He is passionate about leveraging technology to solve complex financial problems, improve user experiences, and foster financial inclusion. Outside of work, Philip enjoys staying updated with market trends, engaging in user research, and continuously enhancing his skills in product management and data analysis.",
  //   speakerImage: PhilipImage,
  //   description: '',
  //   learningPoints: [
  //     'Understand the practice of sprint planning and how it directly impacts the efficiency of the development team.',
  //     'Understand and differentiate the roles of various stakeholders in achieving an effective sprint planning towards team efficiency.',
  //     "Learn the key components of effective sprint planning and it's place in the software development process.",
  //     'Be familiarized with the commonly used tools used in facilitating effective sprint planning',
  //   ],
  //   videoId: '',
  // },
  {
    title:
      'Data and Storytelling - Learn how to communicate data insights to stakeholders through storytelling.',
    date: 'July, 06, 2024',
    time: '8:00PM (WAT)',
    speaker: 'Benedict Ejelonu',
    aboutSpeaker:
      'Ejelonu Benedict is a seasoned data scientist with over 3 years of professional experience specializing in data analysis, predictive modeling, and machine learning engineering. His expertise spans across the fintech and e-logistics industries, where he has successfully developed and implemented data-driven solutions. Benedict is passionate about data visualization and storytelling, helping organizations make informed decisions through clear and compelling data insights. He was also the team lead for Enactus on his campus, a global organization that connects student, academic, and business leaders through entrepreneurial-based projects to empower communities. Under his leadership, the team used active storytelling, data presentation, and reporting to secure grants and funding for projects aimed at helping communities.',
    speakerImage: BenedictImage,
    description:
      'Learn what data storytelling is and its importance in data communication, Learn how to tailor data stories to different audiences, Explore different storytelling frameworks and visualization tools, Identify key elements of a compelling data story.',
    learningPoints: [
      'Understand the importance of storytelling in data communication to stakeholders.',
      'Understand how to tailor data stories to different audiences for maximum impact.',
      'Know the different storytelling frameworks and visualization tools for presenting data.',
      'Learn key elements of a compelling data story (e.g., narrative arc, data visualization).',
    ],
    videoId: 'JtwmRml26zw',
  },
  {
    title: 'How to be Job Ready in a Red Ocean Job Market',
    date: 'May, 04, 2024',
    time: '8:00PM (WAT)',
    speaker: 'Judith Okafor',
    aboutSpeaker: '',
    speakerImage: EricsImage,
    description:
      'In this session, our panelists shared what it takes to move from learning (self taught) to getting a Job, the key steps to take to stand-out in the red ocean Job market, and insights to boost confidence and proactive career management approaches.',
    learningPoints: [
      'Understand in clear terms what it takes to move from learning (self taught) to getting a Job.',
      'Know the key steps (skills and strategies) to take to stand-out in the red ocean Job market.',
      'Gain insight to boost your confidence and proactive career management approaches.',
    ],
    videoId: 'EYj1mh-LLng',
    panelists: [
      {
        name: 'Rita-Maria Oladokun',
        title: 'Software Engineer',
        company: 'Black Boys Code',
        image: ritaMaria,
        about:
          "Rita-Maria is a self-taught full stack developer with over 3 years of experience in server-side and frontend development, database management, cloud platforms, and third-party API integration. In her last role, she helped maintain an e-clinic platform designed to monitor the vital signs of cardiometabolic patients. She is currently a program instructor at Black Boys Code, Toronto, an organisation dedicated to closing the diversity gap in the technology sector by increasing digital literacy levels among black kids. She earned a first-class bachelor's degree in Microbiology from Obafemi Awolowo University, lle-lfe before moving to pursue a Postgraduate Certificate in Information Technology Solutions at Humber College, Toronto, where she continues to expand her skills and knowledge. She is excited to share her insights and experiences about navigating the job market and landing a job in the tech industry. From job hunting strategies to networking tips, Rita hopes to engage with fellow professionals and aspiring developers to offer valuable advice and inspiration. ",
      },
      {
        name: 'Mary Alenoghena',
        title: 'Senior Talent Sourcer',
        company: 'Stack Shift',
        image: maryImage,
        about:
          "Mary is a talent ops professional with over 4 years of experience in technical recruitment and career coaching. She  is skilled in leveraging several sourcing methodologies to find top talent, conducting screening calls and coaching candidates where the need arises. She is very interested in playing an integral role in helping talents explore tech opportunities, navigate career changes and build a successful career. In her spare time, you'll find her reading a book, piecing together a jigsaw puzzle or colouring something nice.",
      },
      {
        name: 'Adetutu Ajeyomi',
        title: 'Associate Technical Recruiter',
        company: 'Bamble',
        image: adetutuImage,
        about:
          'Adetutu Ajeyomi is a dynamic professional with a strong foundation in Human Resource Management. Graduating with a 2nd class upper from the prestigious University of Lagos, she embarked on her career journey with a passion for people and organizational development. Her professional journey began at 21st Century Evolution Systems Limited, where she kickstarted as a Human Resource intern. Over time, her versatility and aptitude for multitasking led her to transition into the role of an Executive Assistant. However, her true calling in human resources never waned, prompting her return to her roots in the HR domain. Currently, Adetutu thrives as a Technical Recruiter at Bamble, where she utilizes her expertise to match top talent with the right opportunities. Her dedication to her field is evidenced by her recent achievement of becoming a certified Professional in Human Resource International (PHRi) through the esteemed Human Resource Certification International. Beyond her corporate endeavors, Adetutu is a woman of many talents. She serves as the Creative Director of De Pastry Planet, a growing home bakery known for its delectable pastries and cakes. Her culinary creations reflect her commitment to excellence and innovation in every aspect of her life. With a blend of professional intuition and entrepreneurial spirit, Adetutu continues to make significant strides in human resources and culinary arts, leaving an indelible mark wherever she goes.',
      },
      {
        name: 'Oluwatobiloba Anani',
        title: 'Software Engineer',
        company: 'Famelab',
        image: tobiImage,
        about:
          'Oluwatobi is a dedicated Full Stack Software Engineer with over 4 years of experience in crafting seamless user interfaces and robust backend solutions. Specializing in technologies like Node.js/Express, PostgreSQL, React, and MongoDB, he has a proven track record of delivering cutting-edge applications. Currently, he enhances digital outreach at Famelab, based in the Netherlands, where he empowers marketers, sales teams, and business owners to refine their strategies. Previously, Oluwatobi led a significant backend educational project, showcasing his capability to manage and execute complex developments. He is deeply passionate about improving user experiences and constantly seeks new ways to create engaging digital environments.',
      },
    ],
  },
  {
    title: 'Web Development Best Practices',
    date: 'Feb, 24, 2024',
    time: '9:00PM (WAT)',
    speaker: 'Uchenna Nnaike',
    aboutSpeaker:
      'Uchenna Nnaike is an innovative software engineer currently contributing to the dynamic and pioneering team at Interswitch. With a passion for crafting efficient and scalable solutions, Uchenna brings his expertise to the forefront of cutting-edge technological advancements in the fintech industry. He honed his skills through years of experience and deeply understands software development principles and best practices. Uchenna is pivotal in developing innovative solutions that streamline financial transactions and enhance user experiences. His contributions span across various projects, encompassing banking and trade, payments, API integrations, etc. When he is not immersed in his professional pursuits, Uchenna dedicates his time to learning other programming languages, researching, engaging in tech conferences, and lending his expertise to open-source projects.',
    speakerImage: EricsImage,
    description:
      'In this webinar, we discussed the best practices in web development. We covered topics such as code quality, performance optimization, and security best practices.',
    learningPoints: [
      'Introduction to HTML',
      'HTML elements and tags',
      'Attributes in HTML',
      'Creating a Simple Web Page',
      'HTML Forms',
      'HTML5 and Modern Practices',
    ],
    videoId: 'c29hIv9iJvI',
  },
  {
    title: 'Getting Started with HTML and CSS',
    date: 'Jan, 27, 2024',
    time: '9:00PM (WAT)',
    speaker: 'Mary Chukwuma',
    aboutSpeaker:
      'Mary Chukwuma is a passionate web developer with extensive experience in HTML, CSS, and JavaScript. She holds a degree in Computer Science from XYZ University and has been actively involved in the web development community for over five years. Mary is known for her commitment to making web development accessible to all and has conducted numerous workshops and training sessions.',
    description:
      'In this introductory HTML webinar, we demystified the building blocks of web development.',
    learningPoints: [
      'Introduction to HTML',
      'HTML elements and tags',
      'Attributes in HTML',
      'Creating a Simple Web Page',
      'HTML Forms',
      'HTML5 and Modern Practices',
    ],
    videoId: 'hHoalzqUois',
  },
  {
    title: 'Intro to Database Management and SQL',
    date: 'Nov, 18, 2023',
    time: '9:00PM (WAT)',
    speaker: 'Theo OKafor',
    aboutSpeaker:
      'Theo Okafor is a software engineer and educator. He is the lead mentor of Dot Campus Africa',
    description:
      'A beginner-friendly introduction to database management and SQL for aspiring software developers, data analysts and other tech enthusiasts.',
    learningPoints: [
      'Introduction to Database Management',
      'The Three Tier Architecture of Application Softwares',
      'Introduction to Relational Databases',
      'Basic SQL',
      'Using PgAdmin to run basic SQL queries',
    ],
    videoId: 'nxFP1m7a3Fw',
  },
]